import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import {Subscription, Subject, BehaviorSubject, Observable} from 'rxjs/Rx';

import { RRServiceOtdb } from "../../services/rr.service.otdb";
import { RRServiceUserList } from "../../services/rr.service.userlist";
import { RRServiceUserState } from "../../services/rr.service.userstate";
import { RRServiceVideoPopup } from "../../services/rr.service.videopopup";
import { RRServiceGalleryVideo } from "../../services/rr.service.galleryvideo";
import { RRServiceVideoData } from "../../services/rr.service.videodata";

import { SaleschartComponent } from '../../helpers/saleschart/saleschart.component'
import { UserselectComponent } from "../../helpers/userselect/userselect.component";
import { DealerselectComponent } from "../../helpers/dealerselect/dealerselect.component";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  private dataSubscription:Subscription;
  private userSubscription:Subscription;
  private data:RRData.SalesStatsData;
  private user:RRData.User;
  private idx:number;
  private accelTxt:string;
  private accelAuth:string;

  private isCollapsed:boolean;
  private accelVid = { cat: 'accel', name: 'accel', desc:'Acclerator Video of the Week'};

  get iconDir(): string {
    return this.isCollapsed ? 'down' : 'up';
  }

  constructor(
    private myElement:ElementRef,
    private _rrServiceUserState:RRServiceUserState,
    private _rrServiceUserList:RRServiceUserList,
    private _rrServiceOtdb: RRServiceOtdb,
    private _rrServiceVideoPopup: RRServiceVideoPopup,
    private _rrServiceGalleryVideo: RRServiceGalleryVideo,
    private _rrServiceVideoData: RRServiceVideoData
  ) {
      this.idx=0;
      this.dataSubscription = _rrServiceOtdb.subscribeOtdbResponse(
        (data) => { this.data = data; console.log('Home.dataSubscription next', data); } );
      this.userSubscription = _rrServiceUserList.subscribeSelectedUser(
        user => {
          this.user = user;
          this.idx = user ? user.adminLevel : 0;
          if( user && user.showAccelVideo ) {
            this.showVideo();
            user.showAccelVideo = false;
          }
          console.log('Home.userSubscription next', user);
        } );
    }

  showVideo()
  {
    var x:number = (this._rrServiceGalleryVideo.getAccelWeek());
    this._rrServiceGalleryVideo.showVideo(this._rrServiceVideoData.AccelGalleryName(x))
  }

  collapsed(): void { this.isCollapsed = true; }
  expanded(): void { this.isCollapsed = false; }

  ngOnInit() {
    this.isCollapsed = false;
    console.log("Home.ngOnInit())", this.isCollapsed, this.iconDir);
  }

  ngOnDestroy() {
    this.dataSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
    console.log('Home.ngOnDestroy()')
  }


}
