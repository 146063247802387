/*
 * Angular
 */
import { Component, OnInit, OnDestroy, AfterViewChecked,
  Input, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { NgForm,FormBuilder,FormGroup,FormControl }    from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subscriber } from 'rxjs/Subscriber';

/*
 * Services
 */
import { RRServiceUserList } from "../../services/rr.service.userlist";
import { RRServiceUserState } from "../../services/rr.service.userstate";
import { UserComponent } from "../user/user.component";

declare var $:any;
import * as _ from 'lodash';


@Component({
  selector: 'app-useredit',
  templateUrl: './useredit.component.html',
  styleUrls: ['./useredit.component.css']
})
export class UsereditComponent implements OnInit, OnDestroy
{
  user:RRData.User;
  multiEdit:boolean;
  editUser: RRData.User;

  addUser:RRData.User;
  userSubscription:Subscription;
  isAddUser:boolean;


  constructor(
    public _rrServiceUserList:RRServiceUserList,
    public _rrServiceUserState:RRServiceUserState)
  {
    this.nextUser(null);
    this.userSubscription = _rrServiceUserList.subscribeSelectedUser(
          user => this.nextUser(user) );
  }

  ngOnInit() {
    console.log('UserEdit.ngOnInit()');
  }
  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    console.log('UserEdit.ngOnDestroy()')
  }

  nextUser(user:RRData.User)
  {
    var dealershipId = this._rrServiceUserList.getCurDealershipId();
    this.addUser = ( dealershipId > 0 ) ? {
        userId: 0, password: '', adminLevel: 0, email: '', bandwidth: 0,
        team: '', dealershipId: dealershipId, login: '',
        dept: 0, reportable:true, dateOfHire: 0,
        image: null,
        lastAccelVideo: 0,
        showAccelVideo: false,
        name: '',
      } : null;
    setTimeout(() => {
      this.multiEdit = this._rrServiceUserState.isSiteAdmin();
      this.isAddUser = user==null;
      this.editUser = user;
      this.setUser();
    }, 0);
    console.log("UserEdit.userSubscription.nextUser",user);
  }

  private updateClass(id:string, className:string, isAdd:boolean)
  {
    var elem = document.getElementById(id);
    if( elem )
    {
      if( isAdd ) elem.classList.add(className);
      else elem.classList.remove(className);
    }
  }
  private setUser()
  {
    this.updateClass('user-edit-add', 'active', this.isAddUser);
    this.updateClass('user-edit-edit', 'active', !this.isAddUser);
    if(this.isAddUser)
    {
      this.user =  this.addUser;
    } else {
       this.user = this.editUser;
    }
  }

  setAddUser(isAddUser:boolean)
  {
    this.isAddUser = isAddUser;
    this.setUser();
  }
}
