import {Component, OnInit, OnDestroy, AfterViewChecked, AfterViewInit} from '@angular/core';
import { RouterLink } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';

/*
 * Services
 */
import { RRServiceUserState } from "../../services/rr.service.userstate";
import { RRServiceUserList } from "../../services/rr.service.userlist";
import { RRServiceOtdb } from "../../services/rr.service.otdb";
import { RRServiceUtil } from "../../services/rr.service.util";
import { Alert } from "../../services/alert";
import { UserselectComponent } from "../../helpers/userselect/userselect.component";
import { DealerselectComponent } from "../../helpers/dealerselect/dealerselect.component";

import * as _ from 'lodash';

@Component({
  selector: 'app-otdb',
  templateUrl: './otdb.component.html',
  styleUrls: ['./otdb.component.css']
})
export class OtdbComponent implements OnDestroy, AfterViewChecked, AfterViewInit
{
  private waiting:boolean;
  private colClassDef:any;
  private intList:Array<number> = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19];
  private floatList:Array<number> = [0,0.5,1,1.5,2,2.5,3,3.5,4,4.5,5,5.5,6,6.5,7,7.5,8,8.5,9,9.5];
  private otdb:RRData.Otdb;
  private zeroOtdb:RRData.Otdb;
  private user:RRData.User;
  private subscription:Subscription;
  private dateElem:any;
  private onDateChange:any;


  constructor(
    private _alert:Alert,
    private _rrServiceUtil:RRServiceUtil,
    private _rrServiceOtdb:RRServiceOtdb,
    private _rrServiceUserList:RRServiceUserList,
    private _rrServiceUserState:RRServiceUserState )
    {
      this.zeroOtdb = { dealershipId: 0, userId: 0, otdbId: 0, entryDate: 0,
              pg: 0, pa: 0, sel: 0, td: 0, ho: 0, wu: 0, ce: 0, rr: 0, conv: 0, used: 0 };
      this.otdb = _.clone(this.zeroOtdb);
      this.colClassDef = this.newColClassDef();
    }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this._rrServiceUtil.dateOnDestroy(this.dateElem);
    console.log('Otdb.ngOnDestroy()')
  }

  ngAfterViewInit() {
    this.dateElem = this._rrServiceUtil.dateOnInit("otdb-otdbDate",
      (iDate:number) =>
        {
          console.log('Otdb.onDateChange(' + iDate + ')');
          if( !iDate || iDate < 0 ) iDate = this._rrServiceUtil.nowAsInt();
          this.dateChange(iDate);
        }
    );
    this.setDate();
    this.subscription = this._rrServiceUserList.subscribeSelectedUser(
          user => {
            this.user = user;
            this.dateChange(0);
            console.log("Otdb.selectedUser.subscription.next",user);
          } );
    console.log('Otdb.ngAfterViewInit()', this.dateElem);
  }

  private setDate()
  {
    this._rrServiceUtil.setDate( this.dateElem, this.otdb.entryDate );
  }

  dateChange(entryDate:number)
  {
    if( this.user )
    {
      if( entryDate == 0 || this.otdb.entryDate != entryDate )
      {
        if( entryDate == 0 ) entryDate = this._rrServiceUtil.nowAsInt();
        _.assign(this.otdb,this.zeroOtdb); // Zero out existing otdb
        this.otdb.entryDate = entryDate;
        this.doFunc('getOtdb', this.otdb );
        console.log('Otdb.dateChange()', this.otdb);
      } else this.setDate();
    }
  }

  private update(fld:string,val:string)
  {
    if( this.otdb.hasOwnProperty(fld) )
      this.otdb[fld] = parseFloat(val);
  }

  private newColClassDef() {
    return {
      dealerSelect:'',
      userSelect:'',
      saveButton:'',
      deleteButton:'',
    };
  }
  private setupColSpan( colClassDef:any,
    firstName:string, secondName:string, isFirst:boolean, isSecond:boolean )
  {
    var x =  isFirst ? 1 : 0;
    if( isSecond ) x++;
    var s = 'col-sm-12';
    var t = '';
    if( x == 2 ) {
      s += ' col-md-6 col-lg-3';
      t = ' col-lg-offset-3';
    }
    colClassDef[firstName] = isFirst ? (s + t) : '';
    colClassDef[secondName] = isSecond ? s : '';
  }

  ngAfterViewChecked() {
    var ccd = this.newColClassDef();
    this.setupColSpan( ccd, 'dealerSelect', 'userSelect',
      this._rrServiceUserState.isSystemAdmin(), true );
    this.setupColSpan( ccd, 'saveButton', 'deleteButton',
      true, this.canDelete() );
    if( !_.isEqual(this.colClassDef,ccd) )
      setTimeout(() => this.colClassDef = ccd, 0);
  }

  private doFunc(name:string, otdb:RRData.Otdb)
  {
    if( otdb && this.user )
    {
      this.waiting = true;

      var func;
      if( !otdb.otdbId )
      {
        otdb.userId = this.user.userId;
        otdb.dealershipId = this.user.dealershipId;
        func = "Add";
      } else func = "Update";

      this._rrServiceOtdb[name](otdb)
        .finally(() => this.waiting = false ).subscribe(
            otdb => {
              if( name == 'deleteOtdb' )
                this.dateChange(0);
              else {
                if( otdb )
                {
                  _.assign(this.otdb,otdb); // copy data into edit object
                  if( this.otdb.entryDate == 0 )
                    this.otdb.entryDate = this._rrServiceUtil.nowAsInt();
                  this.setDate();
                }
              }

              console.log('Otdb.doFunc().next', name, otdb);
            },
            errMsg =>
              this._alert.create(
                {
                  id:'otdb-alert',
                  alertTypeConfig:Alert.ERROR,
                  message:errMsg.toString(),
                  title:((name == "deleteOtdb") ? "Delete" : func) +  " Otdb Entry"
                })
            );
    }
  }

  canDelete()
  {
    return this.otdb.otdbId > 0;
  }

  delete() { this.doFunc('deleteOtdb', this.otdb); }
  onSubmit() { this.doFunc('updateOtdb', this.otdb); }


}
