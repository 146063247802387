/*
 * Angular
 */
import { OnInit, OnDestroy, AfterViewInit, Component } from '@angular/core';
import { NgForm,FormBuilder }    from '@angular/forms';
import {Observable} from 'rxjs/Observable';
import {Subscription} from 'rxjs/Subscription';
import {Subscriber} from 'rxjs/Subscriber';

/*
 * Services
 */
import { RRServiceDealerList } from "../../services/rr.service.dealerlist";
import { RRServiceUserList } from "../../services/rr.service.userlist";
import { RRServiceUserState } from "../../services/rr.service.userstate";
import { RRServiceUtil } from "../../services/rr.service.util";
import { Alert } from "../../services/alert";


import * as _ from 'lodash';


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit, OnDestroy, AfterViewInit
{
  reportReq:RRData.ReportRequest;
  subscription:Subscription;
  downloadLink:any;
  reports:any;
  isSysAdmin:boolean;
  dateElem:any;
  onDateChange:any;

  constructor(
      private _alert:Alert,
      private _rrServiceUtil:RRServiceUtil,
      private _rrServiceDealerList:RRServiceDealerList,
      private _rrServiceUserList:RRServiceUserList,
      private _rrServiceUserState:RRServiceUserState)
  {
    this.reports = [
      {type:RRData.ReportType.OTDB, name:"OTDB"},
      {type:RRData.ReportType.USAGE, name:"Usage"},
      {type:RRData.ReportType.ANALYSIS, name:"Analysis"},
      {type:RRData.ReportType.USER, name:"User"},
    ]
    this.reportReq = {
      date: this._rrServiceUtil.nowAsInt(),
      showUserDetail: true,
      showTeamSummary: true,
      showDealerSummary: true,
      showRankingTables: true,
      showParGraphs: true,
      multiDealer: false,
      dealershipId: 0,
      userId: 0,
      type: RRData.ReportType.OTDB
    };
    this.onDateChange = (iDate:number) =>
      {
        console.log('Reports.update(' + iDate + ')');
        this.reportReq.date = iDate;
        if( !this.reportReq.date )
        {
          this.reportReq.date = this._rrServiceUtil.nowAsInt();
          this.setDate();
        }
      }
  }

  showColumn(name:String)
  {
    var isUserReport = this.reportReq.type == RRData.ReportType.USER;
    if( isUserReport && name != 'multiDealer' ) return false;

    switch( name )
    {
      case 'showTeamSummary':
        return this._rrServiceUserState.isTeamLeader();
      case 'showDealerSummary':
        return this._rrServiceUserState.isSiteAdmin();
      case 'multiDealer':
        return this._rrServiceUserState.isSystemAdmin();
      default:
        /*
          case 'reportDate':
          case 'showUserDetail':
          case 'showRankingTables':
          case 'showParGraphs':
        */
        return true;
    }

  }

  ngAfterViewInit() {
    this.selectReportType(RRData.ReportType.OTDB);
    console.log("Reports.ngAfterViewInit():");
  }

  ngOnInit() {
    this.downloadLink = document.getElementById('reports-page-download-link');
    this.dateElem = this._rrServiceUtil.dateOnInit("reports-reportDate", this.onDateChange );
    this.setDate();
    this.subscription = this._rrServiceUserList.subscribeSelectedUser(
          user => {
            this.isSysAdmin = this._rrServiceUserState.isSystemAdmin();
            this.reportReq.dealershipId = this._rrServiceUserList.getCurDealershipId();
            this.reportReq.userId = user ? user.userId : 0;
            console.log("Reports.users.subscription.next",user);
          } );
    console.log("Reports.ngOnInit()", this.dateElem);
  }
  ngOnDestroy() {
    this._rrServiceUtil.dateOnDestroy(this.dateElem);
    this.subscription.unsubscribe();
    console.log('Reports.ngOnDestroy()')
  }

  private setDate()
  {
    this._rrServiceUtil.setDate( this.dateElem, this.reportReq.date );
  }




  private updateClass(id:string, className:string, isAdd:boolean)
  {
    var elem = document.getElementById(id);
    if( elem )
    {
      if( isAdd ) elem.classList.add(className);
      else elem.classList.remove(className);
    }
  }

  selectReportType(reportType:RRData.ReportType)
  {
    _.each(this.reports, report =>
      {
        this.updateClass('reports-report-type-' + report['type'], 'active', reportType == report['type'] )
      }
    );
    this.reportReq.type = reportType;
    console.log('Reports.selectReportType()', reportType);
  }

  isDemoDealer(): boolean
  {
    return this.reportReq.dealershipId==4;
  }

  genDemoData(): void
  {
    console.log('Reports.genDemoData()');
    let config = {
      id:'report-alert',
      alertTypeConfig:Alert.SUCCESS,
      title: 'Generate Demo Data',
      message: 'Success!',
      timeout:0,
    };
    this._rrServiceUserState._rrServiceServer.post( 'report/demo' )
      .subscribe(
        ret => {
          console.log('Reports.genDemoData(): ', ret);
          this._alert.create(config);
        },
        errMsg => {
          console.error('Reports.genDemoData().catch(): ', errMsg);
          config.alertTypeConfig = Alert.ERROR;
          config.message = errMsg.toString();
          this._alert.create(config);
        }
    );

  }

  onSubmit(): void {
    console.log('Reports.onSubmit()', 'reportReq:', this.reportReq);

    let dealer = this._rrServiceDealerList.getDealer(this.reportReq.dealershipId);
    let report = _.find(this.reports,{type:this.reportReq.type});
    let reportName =
      (( dealer && report )
        ? report['name'] + '-' + dealer.login +  '-' + this.reportReq.date
        : "unknown") + ".pdf";

    let srv = this._rrServiceUserState._rrServiceServer;
    var url = srv.serverUrl('report/' + reportName ) + '?' +
      srv.addParam(undefined, 'reportReq', JSON.stringify(this.reportReq) );
    this.downloadLink.href=url;
    this.downloadLink.click();

  }

}
