import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root'})
export class RRConfig {

  config = {
    useMock:false,
    responseDelay:{ login:  0, other:0},
  };

  get(param:string):any
  {
    return this.config[param];
  }

}
