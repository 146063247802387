import { Injectable } from '@angular/core';
import {Observable, Subscription, Subject, BehaviorSubject} from 'rxjs/Rx';
import {RRConfig} from "./rr.service.config";
import {RRServiceServer} from "./rr.service.server";
import {RRServiceVideoData} from "./rr.service.videodata";
import * as $ from 'jquery';
import * as _ from 'lodash';

@Injectable({providedIn: 'root'})

export class RRServiceVideo {

  constructor(
    private config:RRConfig,
    private _rrServiceServer:RRServiceServer,
    private _videoData:RRServiceVideoData)
  {
  }

  specialDivMediaName(div:any)
  {
    var name = '';
    if( div && div.firstElementChild && div.firstElementChild.tagName
      && div.firstElementChild.tagName.toLowerCase() == 'a' )
        name = div.firstElementChild.name;
    return name;
  }

  private pauseAll(data?:any)
  {
    this._videoData.pauseAll(data);
  }

  sources(cat:string,name:string,isAudio:boolean): Array<any> {
    return this._videoData.sources(cat,name,isAudio);
  }

  vid(cat:string, name:string):any
  {
    return this._videoData.vid(cat,name);
  }

  private sendActivityLog(activityType:RRData.ACTIVITY_TYPE, name:string)
  {
    console.log("sendActivityLog Start: ", name);
    let activtyData = JSON.stringify({ activityType:activityType, name:name });
    return this._rrServiceServer.post('activity', { body:activtyData })
      .map( ret => <RRData.Activity>ret.result ) // Map result to Activity
      .subscribe(
        result => {
          console.log("sendActivityLog: ", result);
        },
        errMsg => {
          console.warn("sendActivityLog: ", errMsg);
        }
      );
  }

  joinName(cat:string, name:string):string {
    return cat + ':' + name;
  }

  splitName(name:string):Array<string> {
    return name ? name.split(':') : [];
  }

  cmpDiv(div:any, cat:string, name:string):boolean {
    let n1 = this.joinName(cat, name);
    let n2 = this.specialDivMediaName(div);
    return n1 === n2;
  }

  dataFromDiv(div:any):any {
    let name = this.specialDivMediaName(div);
    if( name ) {
      let parts = this.splitName(name);
      if(parts && parts.length==2) {
        return this._videoData.vid(parts[0],parts[1]);
      }
    }
    return undefined;

  }

  onInitDiv(div:any, cat:string, name:string,isAudio?:boolean, autoStart?:boolean, callback?:any, type?:string )
  {
    this.onDestroyDiv(div);

    var data = this._videoData.vid(cat,name);
    console.log('RRServiceVideo.onInitDiv', cat, name, data);
    if( div && data )
    {
      var a = document.createElement("a"); // click event
      a.name = this.joinName(cat, name);
      var tag;
      var sources;
      if( isAudio )
      {
        tag = 'audio';
        sources = data.audioSources;
      } else {
        tag = 'video';
        sources = data.videoSources;
      }
      var media:HTMLMediaElement = <HTMLMediaElement>(<any>(document.createElement(tag)));
      media.id = 'video-' + name;
      media.className = "responsive-" + tag ;
      if( !isAudio )
        media.setAttribute("poster", data.poster );
      media.setAttribute("controls", "controls" );
      var srcKillCache = data.isAccelVideo ? ('?killcache=' + (new Date()).getTime()/(1000*60*60*4)) : '';
      _.each(sources, (msrc) => {
        if( !type || type == msrc['type'] )
        {
          var source = document.createElement("source");

          source.setAttribute('src',msrc['src'] + srcKillCache);
          source.setAttribute('type',msrc['type']);
          media.appendChild(source);
        }
      } )
      media.appendChild(document.createTextNode(
        "Your browser doesn't support HTML5 " + tag + " tag."));

      var isPlaying = function() {
          return media.currentTime > 0 && !media.paused
            && !media.ended && media.readyState > 2;
      }
      var play = function() { media.play(); }
      var pause = function() { media.pause(); }
      var toggle = function() {
          if( isPlaying() ) pause();
          else play();
      }
      var getMedia = function() { return media };
      var getIsAudio = function() { return isAudio; }

      data.funcs = {
        isPlaying:isPlaying,
        play:play,
        pause:pause,
        toggle:toggle,
        isAudio:getIsAudio,
        media:getMedia,
      };

      var sendStart=true;
      var sendComplete=true;
      var callbackData = { media:media, filename:"" };
      var logCallback = function(func:string,_data:any) {
        if( callback ) callback(name,func,_data);
        console.log('RRServiceVideo.media.' + name + func, callback ? "Has Callback" : "No Callback", sendStart, sendComplete, _data, data );
      }
      _.each(['loadstart', 'canplay', 'ended', 'seeking', 'playing'], (func) => {
        media.addEventListener(func, () => {
          switch(func)
          {
            case 'loadStart':
              callbackData.filename = media.currentSrc ? media.currentSrc.replace(/^.*[.]/, '') : "";
              break;
            case 'canplay':
              if( autoStart ) setTimeout(() => play());
              break;
            case 'seeking':
              sendComplete=false;
              break;
            case 'playing':
              if( sendStart ) {
                if( data.logActivity )
                  this.sendActivityLog(
                    data.isAccelVideo
                      ? RRData.ACTIVITY_TYPE.ACCEL_VIDEO_COMPLETE
                      : RRData.ACTIVITY_TYPE.VIDEO_START,name);
                sendStart=false;
              }
              break;
            case 'ended':
              if( sendComplete ) {
                if( data.logActivity )
                  this.sendActivityLog(
                    data.isAccelVideo
                     ? RRData.ACTIVITY_TYPE.ACCEL_VIDEO_COMPLETE
                     : RRData.ACTIVITY_TYPE.VIDEO_COMPLETE,name);
                sendComplete=false;
              }
              break;
          }
          logCallback(func,callbackData);

        }, false); // End media.addEventListener
      }); // End _.forEach


      a.addEventListener('click', (event) => {
        event.preventDefault();
        this.pauseAll(data);
        toggle();
        logCallback('click',callbackData);
      }, false);

      a.appendChild(media);
      div.appendChild(a);

      logCallback('onInitDiv',callbackData);
      return data.funcs;
    }
    return null;
  }

  onDestroyDiv(div:any):void
  {
    this.pauseAll();
    let data = this.dataFromDiv(div)
    if( data ) delete data.funcs;
    $(div).empty();
  }
}
