import { Injectable } from '@angular/core';
import {Subscription, BehaviorSubject, Observable} from 'rxjs/Rx';
import {RRServiceServer} from "./rr.service.server";
import {RRServiceUserState} from "./rr.service.userstate";
import * as _ from 'lodash';

declare type Signaler = (value: Array<RRData.SeminarTransaction>) => void;
declare type SeminarTransactionSignaler = (value: RRData.SeminarTransaction) => void;

@Injectable({ providedIn: 'root'})
export class RRServiceSeminarTransactionList {

  private seminarTransactions: Array<RRData.SeminarTransaction>;
  private curSeminarTransaction: RRData.SeminarTransaction;
  private subscription: Subscription;
  private seminarTransactionListSubject:BehaviorSubject<Array<RRData.SeminarTransaction>>;
  private selectedSeminarTransactionSubject:BehaviorSubject<RRData.SeminarTransaction>;
  private transactionId: number;


  constructor(public _rrServiceUserState:RRServiceUserState) {
    this.seminarTransactions = new Array<RRData.SeminarTransaction>();
    this.seminarTransactionListSubject = new BehaviorSubject<Array<RRData.SeminarTransaction>>(this.seminarTransactions);
    this.selectedSeminarTransactionSubject = new BehaviorSubject<RRData.SeminarTransaction>(null);

    this.subscription =
      _rrServiceUserState.subscribe( userstate => {
        this.fetchSeminarTransactions();
      });
  }

  private publish() {
    if( this.seminarTransactions.length )
        this.seminarTransactions = _.sortBy(this.seminarTransactions, 'login' );
    this.seminarTransactionListSubject.next(_.cloneDeep(this.seminarTransactions));
  }

  private publishSeminarTransaction() {
    this.selectedSeminarTransactionSubject.next(this.curSeminarTransaction);
  }

  subscribeSeminarTransactionList( signal: Signaler ): Subscription
  {
    return this.seminarTransactionListSubject.subscribe( signal );
  }

  subscribeSelectedSeminarTransaction( signal: SeminarTransactionSignaler ): Subscription
  {
    return this.selectedSeminarTransactionSubject.subscribe( signal );
  }


  getSeminarTransactions() { return this.seminarTransactions; }


  getCurSeminarTransaction(transactionId:number):RRData.SeminarTransaction {
    return _.find(this.seminarTransactions, {transactionId:transactionId});
  }

  showSeminarTransaction(transaction?:RRData.SeminarTransaction)
  {
    if( !transaction ) transaction = this.getCurSeminarTransaction(transaction.transactionId);
    if( !transaction ) return '';
    return transaction;
  }

  private apiCall() { return 'seminars'}

  private fetchSeminarTransactions():void
  {
    this._rrServiceUserState._rrServiceServer.get(this.apiCall())
      .map( ret => <Array<RRData.SeminarTransaction>>ret.result )
      .subscribe(
          ret => {
            this.seminarTransactions = ret;
            console.log("RET: ", ret);
          },
          err => {
            console.error('RRServiceDealerList.fetchDealers().catch(): ', err);
          }
      );
  }

  getTransactionId() : number{
    return this.transactionId;
  }

  updateSeminarTransaction(transaction:RRData.SeminarTransaction): void
  {
    console.log(transaction);
    this._rrServiceUserState._rrServiceServer.post(this.apiCall(), { body: JSON.stringify(transaction) } )
      .map( ret => <RRData.SeminarTransaction>ret.result).subscribe(ret => {console.log(ret.transactionId); this.transactionId = ret.transactionId});
  }
}
