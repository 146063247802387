import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AboutComponent } from './about/about.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { ContactComponent } from './contact/contact.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SeminarsComponent } from './seminars/seminars.component'

import { HomeComponent } from './tools/home/home.component';
import { OtdbComponent } from './tools/otdb/otdb.component';
import { SalestrainingComponent } from './tools/salestraining/salestraining.component';
import { PagesComponent } from './tools/pages/pages.component';
import { ReportsComponent } from './tools/reports/reports.component';
import { UsereditComponent } from './tools/useredit/useredit.component';
import { DealereditComponent } from './tools/dealeredit/dealeredit.component';
import { GalleryComponent } from './tools/gallery/gallery.component';

const appRoutes: Routes = [
  { path: 'Welcome',    component: WelcomeComponent },
  { path: 'About',      component: AboutComponent },
  { path: 'Contact',    component: ContactComponent },
  { path: 'Seminars',   component: SeminarsComponent},
  { path: 'Dashboard',  component: DashboardComponent,  children: [
    { path: '',               component: HomeComponent },
    { path: 'Home',           component: HomeComponent },
    { path: 'Otdb',           component: OtdbComponent },
    { path: 'SalesTraining',  component: SalestrainingComponent },
    { path: 'Reports',        component: ReportsComponent },
    { path: 'Documentation',  component: PagesComponent },
    { path: 'Users',          component: UsereditComponent },
    { path: 'Dealers',        component: DealereditComponent },
    { path: 'Gallery',        component: GalleryComponent },
  ] },
  { path: '', redirectTo: '/Welcome', pathMatch: 'full' },
  //{ path: '**', component: PageNotFoundComponent }
];


@NgModule({
  exports: [ RouterModule ],
  imports: [
    RouterModule.forRoot( appRoutes
      //, { enableTracing: true }
    ),
  ]
})
export class AppRoutingModule {}
