/*
 * Angular
 */
import { Component, OnInit, OnDestroy, OnChanges, AfterViewInit
  , Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subscriber } from 'rxjs/Subscriber';


/*
 * Services
 */
import { RRServiceUserState } from "../../services/rr.service.userstate";
import { RRServiceUserList } from "../../services/rr.service.userlist";
import { RRServiceUtil } from "../../services/rr.service.util";
import { Alert } from "../../services/alert";
import { UserselectComponent } from "../../helpers/userselect/userselect.component";
import { DealerselectComponent } from "../../helpers/dealerselect/dealerselect.component";

import * as _ from 'lodash';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges
{

  waiting:boolean;
  colClassDef:any;
  adminLevel:number;
  passwdElem:any;
  dateElem:any;
  onDateChange:any;

  @Input('multiEdit') multiEdit:boolean;
  @Input('user') user:RRData.User;

  userClone:RRData.User;

// {{user.userId==0 ?  : ''}}

  constructor(
    private _alert:Alert,
    private util:RRServiceUtil,
    private _rrServiceUserState:RRServiceUserState,
    public _rrServiceUserList:RRServiceUserList) {}

  ngOnInit() {
    this.colClassDef = this.newColClassDef();
    this.adminLevel = this._rrServiceUserState.getAdminLevel();
    this.passwdElem = document.getElementById("usersetup-password");
    console.log('User.ngOnInit()', this.user, this.passwdElem);
  }

  ngAfterViewInit() {
    this.dateElem = this.util.dateOnInit("date-of-hire",
      (iDate:number) =>
        {
          console.log('Dealer.onDateChange(' + iDate + ')');
          this.user.dateOfHire = iDate;
        }
    );
    this.setDate();
    console.log('Dealer.ngAfterViewInit()', this.user, this.dateElem);
  }

  ngOnDestroy() {
    console.log('User.ngOnDestroy()')
  }

  ngOnChanges(changes:any)
  {
    let user = _.clone(changes['user']['currentValue']);
    console.log('User.ngOnChanges()', user);
    this.userClone = user;

    // _.clone()
    var ccd = this.newColClassDef();
    this.setupColSpan( ccd, 'dealerSelect', 'userSelect',
      this._rrServiceUserState.isSystemAdmin(), user.userId > 0 );
    this.setupColSpan( ccd, 'saveButton', 'deleteButton',
      true, this.canDelete() );
    if( !_.isEqual(this.colClassDef,ccd) )
    {
      this.colClassDef = ccd;
    }
    if( this.passwdElem )
    {
      if( this.user.userId > 0 )
        this.passwdElem.removeAttribute('required');
      else
        this.passwdElem.setAttribute('required','true');
    }
    this.setDate();
  }

  dirty()
  {
    return !_.isEqual(this.user, this.userClone);
  }


  selectAdminLevel(value:string)
  {
    if( value )
    {
      var a = parseInt(value);
      this.user.adminLevel = ( a > this.adminLevel ) ? this.adminLevel : a;
      console.log("Converting starting dow to int: ", this.user.adminLevel, value );
    }
  }

  private setDate()
  {
    console.log('Dealer.setDate()',this.dateElem, this.user.dateOfHire);
    this.util.setDate( this.dateElem, this.user.dateOfHire);
  }

  private newColClassDef() {
    return {
      dealerSelect:'',
      userSelect:'',
      saveButton:'',
      deleteButton:'',
    };
  }
  private setupColSpan( colClassDef:any,
    firstName:string, secondName:string, isFirst:boolean, isSecond:boolean )
  {
    var x =  isFirst ? 1 : 0;
    if( isSecond ) x++;
    var s = 'col-sm-12';
    var t = '';
    if( x == 2 ) {
      s += ' col-md-6 col-lg-3';
      t = ' col-lg-offset-3';
    }
    colClassDef[firstName] = isFirst ? (s + t) : '';
    colClassDef[secondName] = isSecond ? s : '';
  }

  doFunc(name:string)
  {
    this.waiting = true;
    let func = this.user.userId ? "Update" : "Add";
    let config = {
      id:'user-alert',
      alertTypeConfig:Alert.SUCCESS,
      message:'Success!',
      title:((name == "deleteUser") ? "Delete" : func) +  " User Entry",
      timeout:0,
    };

    this._rrServiceUserList[name](this.user)
      .finally(() => {
          this.waiting = false;
        }).subscribe(
          result => {
            this._alert.create(config);
          },
          errMsg => {
            config.alertTypeConfig = Alert.ERROR;
            config.message = errMsg.toString();
            this._alert.create(config);
          }
        );
  }

  canDelete()
  {
    return this.multiEdit &&
      this.user.userId > 0 &&
      this._rrServiceUserState.getUserId() != this.user.userId;
  }

  delete(event:any) {
    this.util.blurButtons(event);
    this._alert.create( {
      id:'user-alert',
      alertTypeConfig:Alert.WARNING,
      message:
          `Deletion of a user is a serious action. It CANNOT be undone.
           User action logs will also be deleted. User Otdb entries will be
           retained however and placed in a deleted user category. However they
           can no longer be distinguished into teams. Be very, very certain before
           you continue. If you decide you do not wish to delete this user then
           click the 'Cancel' button`,
      title:"Confirm Delete!",
      alertTypeButton: Alert.ERROR,
      onSuccess: () => this.doFunc('deleteUser'),
      successButton: 'Delete',
    } );
  }

  onSubmit(event:any) {
    this.util.blurButtons(event);
    this.doFunc('updateUser');
  }

}
