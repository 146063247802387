import { Injectable } from '@angular/core';
import { RRServiceVideo } from './rr.service.video';
import { RRServiceVideoData } from './rr.service.videodata'
import { RRServiceHttp } from './rr.service.http'
import { BehaviorSubject } from 'rxjs';

declare var $:any;
import * as _ from 'lodash';

@Injectable({ providedIn: 'root'})
export class RRServiceGalleryVideo
{

  private modalDiv: any;
  private titleH4: any;
  private quoteH3: any;
  private authorH4: any;
  private mediaDiv: any;
  private modal:(arg:any) => void;
  private funcs:any;
  public accel:any;
  public accelTxt: string;
  public accelList:BehaviorSubject<Array<any>>;



  constructor( private _RRServiceVideo:RRServiceVideo,
              private _rrServiceVideoData:RRServiceVideoData,
            private http:RRServiceHttp )
  {
    this.accelList = new BehaviorSubject([]); // 0 is the initial value
    this.accel = { Videos:[] };
    http.request("GET", "/protected/video/accel/accel_list.json?reqTime="
      + ((new Date()).getMilliseconds()), null)
      .subscribe(res => {
        console.log("JSON video list: ", res);
        this.accel = res;
        let week = this.getAccelWeek()
        let va = res["Videos"].slice( 0, week)
        console.log("RRServiceGalleryVideo.constructor", va.length, week)
        this.accelList.next(va);
        this._rrServiceVideoData.setAccelGallery(res);
        this.setAccelTxt();
      });

    this.init();
  }

  private init():void {
    if( !this.modal )
    {
      this.modalDiv = document.getElementById('rr-video-popup');
      if( this.modalDiv )
      {
        this.titleH4 = this.modalDiv.getElementsByClassName("modal-title")[0];
        this.mediaDiv = this.modalDiv.getElementsByClassName("rr-media-body")[0];
        if( this.titleH4 &&  this.mediaDiv)
        {
          let m = $(this.modalDiv);
          let modal = m.modal;
          if( modal ) {
            modal = modal.bind(m);
            this.modal = modal;
            this.modal({show:false});
            m.on('hide.bs.modal', (e) => this.stop());
            console.log("RRServiceVideoPopup.init", this.modal ? 'modal' : 'failed');
          } else {
            console.log("RRServiceVideoPopup.showVideo failed for lack of modal");
          }
        } else {
          console.log("RRServiceVideoPopup.showVideo failed for lack of div");
        }
      } else {
        console.log("RRServiceVideoPopup.showVideo failed for lack of pri div");
      }
    }
  }

  private stop() {
    if( this.funcs ) this.funcs.pause();
  }

  public showVideo(idx:string)
  {
    this.init();
    if( this.modal )
    {
      if( this._RRServiceVideo.cmpDiv(this.mediaDiv, "accelGallery", idx) )
      {
          // In here current modal exists and is for this cat and name
          this.modal('show');
          this.funcs.play();
      } else {
        this.funcs = this._RRServiceVideo.onInitDiv(this.mediaDiv, "accelGallery", idx);
        this.modal('show');
        this.funcs.play();
      }
    }
  }

  // Thu Dec 14 2017 14:00:00 GMT-0800
  private readonly millisAt20171214_1400 = 1513288800000;
  private readonly msPerWeek = 86400000*7

  public getAccelWeek(): number {
    // Get number of weeks by dividing millis between now and Dec 14 2017 14:00:00 (feature startup)
    // by the number of millis in a week then round down to integer weeks
    var now = (new Date()).getTime();
    var delta = now-this.millisAt20171214_1400;
    var week = Math.floor(delta/this.msPerWeek)-10
    if( this.accel.Videos.length > 0 ) {
      return (week % this.accel.Videos.length) + 1
    }
    return 0;
  }

  public setAccelTxt() {
    let idx = this.getAccelWeek()-1
    if( idx >= 0 ) {
      let vid = this.accel.Videos[idx]
      this.accelTxt = vid.H3;
      this.authorH4 = vid.H4;
      console.log("Accel text,auth: ", this.accelTxt, this.authorH4);
    }
  }

  public getAccelTxt() : string { return this.accelTxt; }
  public getAccelAuth() : string { return this.authorH4; }

}
