import { Injectable } from '@angular/core';
import { RRServiceVideo } from './rr.service.video';

declare var $:any;
import * as _ from 'lodash';

@Injectable({ providedIn: 'root'})
export class RRServiceVideoPopup
{

  private modalDiv: any;
  private titleH4: any;
  private mediaDiv: any;
  private modal:(arg:any) => void;
  private funcs:any;

  constructor( private _RRServiceVideo:RRServiceVideo)
  {
    this.init();
  }

  private init():void {
    if( !this.modal )
    {
      this.modalDiv = document.getElementById('rr-video-popup');
      if( this.modalDiv )
      {
        this.titleH4 = this.modalDiv.getElementsByClassName("modal-title")[0];
        this.mediaDiv = this.modalDiv.getElementsByClassName("rr-media-body")[0];
        if( this.titleH4 &&  this.mediaDiv )
        {
          let m = $(this.modalDiv);
          let modal = m.modal;
          if( modal ) {
            modal = modal.bind(m);
            this.modal = modal;
            this.modal({show:false});
            m.on('hide.bs.modal', (e) => this.stop());
            console.log("RRServiceVideoPopup.init", this.modal ? 'modal' : 'failed');
          } else {
            console.log("RRServiceVideoPopup.showVideo failed for lack of modal");
          }
        } else {
          console.log("RRServiceVideoPopup.showVideo failed for lack of div");
        }
      } else {
        console.log("RRServiceVideoPopup.showVideo failed for lack of pri div");
      }
    }
  }

  private stop() {
    if( this.funcs ) this.funcs.pause();
  }

  public showVideo(cat:string, name:string, desc:string, isAudio?:boolean, autoStart?:boolean, callback?:any, type?:string )
  {
    this.init();
    if( this.modal )
    {
      if( this._RRServiceVideo.cmpDiv(this.mediaDiv, cat, name) )
      {
          // In here current modal exists and is for this cat and name
          this.modal('show');
          this.funcs.play();
      } else {
        this.funcs = this._RRServiceVideo.onInitDiv(this.mediaDiv, cat, name, isAudio, autoStart, callback, type );
        this.titleH4.innerText=desc;
        this.modal('show');
        this.funcs.play();
      }
    }
  }
}
