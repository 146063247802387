import { Injectable } from '@angular/core';

declare var Modernizr:any;
declare var $:any;
declare type DateChangeSignaler = (idate: number) => void;

@Injectable({ providedIn: 'root'})
export class RRServiceUtil {

  hasDateFunction:boolean;

  constructor() {
    this.hasDateFunction = true; // most do
    $().ready(
      () => {
        try {
            this.hasDateFunction = Modernizr.inputtypes.date;
        } catch( err ) {
            console.warn("Error in trying to init RRServiceUtil", err);
        }
        console.log("RRServiceUtil.constructor()", this.hasDateFunction);
      }
    );
  }

  private isButton(elem:any)
  {
    if( !elem ) return false;
    var tagName = event['tagName'];
    return tagName ? (tagName.toLowerCase() == 'button') : false;
  }

  blurButtons(event)
  {
    try {
      if( !event ) return;
      if( this.isButton(event) ) event.blur();
      else {
        var elems = event.currentTarget.getElementsByTagName('button');
        if( !elems ) return;
        for( var idx=0; idx<elems.length; idx++)
          elems[idx].blur();
      }
    } catch (err) {}
  }

  /**
	 * intToDate - utilitiy function to convert integer date object used by server into a javascript
	 * 			Date object
	 */
	intToDate(iDate:number):any
	{
    if( iDate )
    {
      try {
        let d = this.intToYmd(iDate)
  			return new Date(d.year,d.month,d.day);
  		} catch( err ) {
  			console.log(err);
  			return new Date();
  		}
    }
    return '';
	}

  intToYmd(iDate:number):any
	{

    let day = iDate % 100; iDate = ~~(iDate/100); // ~~ turns floating point result into Integer division
    let month = iDate % 100; iDate = ~~(iDate/100); // ~~ turns floating point result into Integer division
    let year = iDate % 10000;
    return [year, month, day]
	}

  ymdToInt(d:any):number
  {
    var iDate = (d[0] % 10000); iDate *= 100;
    iDate += (d[1] % 100); iDate *= 100;
    iDate += (d[2] % 100);
    return iDate;
  }

  nowAsInt() { return this.dateToInt(new Date());}

  dateToInt(date?:Date)
  {
    if( date )
    {
      try {
        return  this.ymdToInt([date.getFullYear(), date.getMonth()+1, date.getDate()])
      } catch( err ) {
        console.log(err);
      }
    }
    return 0;
  };


  private parseDateStr(value:string)
  {
    let x = 0;
    try {
      x = parseInt(value.replace(/-/g, ""), 10);
    } catch( err ) {
      console.warn("util.getDate(" + value + ")", err);
    }
    return x;
  }

  dateOnInit(id:string, onChange:DateChangeSignaler)
  {
    let elem = <HTMLInputElement>document.getElementById(id);
    if( !elem ) return null;
    let $elem = $(elem);

    if( this.hasDateFunction )
    {
      $elem.on('blur',(event) => onChange(this.parseDateStr(elem.value)) );
    } else {
      let $zdp = $elem.data('Zebra_DatePicker');
      if( !$zdp )
        $elem.Zebra_DatePicker( {
          show_icon:false,
          format: 'm/d/Y',
          onClear: () => onChange(0),
          onSelect: (d1,d2,d3,e) => onChange(this.parseDateStr(d2)),
        });
      console.log("RRServiceUtil.dateOnInit: ", $elem, $elem.data('Zebra_DatePicker'), elem);
    }
    return $elem
  }

  dateOnDestroy($elem)
  {
    if( !$elem ) return;
    try {
        var $zdp = $elem.data('Zebra_DatePicker');
        if( $zdp ) $zdp.destroy();
        else $elem.off('blur');
        console.log("RRServiceUtil.dateOnDestroy: ", $zdp, $elem);
    } catch( err ) {
        console.warn("RRServiceUtil.dateOnDestroy Error in trying to init datepicker", err, $elem);
    }
  }

  private formatDate(iDate:number,useZebraDate:boolean)
  {
    if( iDate )
    {
      let x = '' + iDate;
      let d = [x.substring(0,4),x.substring(4,6),x.substring(6,8)];
      if( useZebraDate )
      {
        // In here use Zebra format mm/dd/yyyy
        return d[1] + '/' + d[2] + '/' + d[0];
      } else {
        // In here use html YYYY-mm-dd format
        return d[0] + '-' + d[1] + '-' + d[2];
      }
    } else return '';
  }

  setDate($elem:any,iDate?:number):boolean
  {
    if( !$elem ) return false;
    if( !iDate ) iDate=0; // Make sure iDate is number

    var $zdp = $elem.data('Zebra_DatePicker');
    if( $zdp ) {
      if( iDate ) $zdp.set_date(this.formatDate(iDate,true));
      else $zdp.clear_date();
    } else {
      $elem.val(this.formatDate(iDate,false));
    }
  }



}
