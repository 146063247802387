import { Component } from '@angular/core';
import { setTheme } from 'ngx-bootstrap/utils';

import {WelcomeComponent} from "./welcome/welcome.component";
import {AboutComponent} from "./about/about.component";
import {ContactComponent} from "./contact/contact.component";
import {DashboardComponent} from "./dashboard/dashboard.component";

import {Subscription} from 'rxjs/Subscription';
import {RRServiceUserState} from "./services/rr.service.userstate";
import {RRServiceTouch} from "./services/rr.service.touch";

import * as $ from "jquery";
import * as _ from "lodash";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  routerOptions:Array<any> = [
    { name: 'Welcome',  display: 'Welcome'},
    { name: 'About',    display: 'About'},
    { name: 'Contact',  display: 'Contact'},
    { name: 'Seminars', display: 'Seminars'},
    { name: 'Dashboard',display: 'Dashboard'} ];
  private loginIdx:number;
  private subscription: Subscription;
  private $navbar:any;

  private setLoginDisplay() {
    var display = this._rrServiceUserState.isLoggedIn() ?  'Dashboard' : 'Login';
    this.routerOptions[this.loginIdx].display = display;
  }

  constructor(
      private _rrServiceUserState:RRServiceUserState,
      private _rrServiceTouch:RRServiceTouch
    ) {
    setTheme('bs4');
    this.loginIdx = _.findIndex(this.routerOptions, opt => opt.name == "Dashboard");
    this.setLoginDisplay();
  }

  ngOnInit() {
    // tool tip options
    var options = {
      selector: '[data-toggle="tooltip"]',
      delay: { "show": 500, "hide": 100 },
      trigger: 'hover',
    };
    // this logic sets up tool tips for non touch devices but
    // disables for touch devices
    this._rrServiceTouch.subscribeHasTouch( (hasTouch) => {
      let tooltip = $('body')['tooltip'];
      if( tooltip )
      {
      }
    });

    console.log('App.ngOnInit().subscribe');
    this.subscription = this._rrServiceUserState.subscribe(
      resp => this.setLoginDisplay() );
  }

  ngOnDestroy() {
    console.log('App.ngOnDestroy().unSubscribe')
    this.subscription.unsubscribe();
  }


}
