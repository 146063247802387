import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { ImageCropperModule } from 'ngx-image-cropper';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';

import { AppComponent } from './app.component';
import { AboutComponent } from './about/about.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { ContactComponent } from './contact/contact.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DealerComponent } from './tools/dealer/dealer.component';
import { DealereditComponent } from './tools/dealeredit/dealeredit.component';
import { HomeComponent } from './tools/home/home.component';
import { LoginComponent } from './tools/login/login.component';
import { OtdbComponent } from './tools/otdb/otdb.component';
import { PagesComponent } from './tools/pages/pages.component';
import { ReportsComponent } from './tools/reports/reports.component';
import { SalestrainingComponent } from './tools/salestraining/salestraining.component';
import { UserComponent } from './tools/user/user.component';
import { UsereditComponent } from './tools/useredit/useredit.component';
import { CropavatarComponent } from './helpers/cropavatar/cropavatar.component';
import { DealerselectComponent } from './helpers/dealerselect/dealerselect.component';
import { PageComponent } from './helpers/page/page.component';
import { SaleschartComponent } from './helpers/saleschart/saleschart.component';
import { UserselectComponent } from './helpers/userselect/userselect.component';
import { AppRoutingModule } from './/app-routing.module';
import { SeminarsComponent } from './seminars/seminars.component';
import { GalleryComponent } from './tools/gallery/gallery.component';


@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    WelcomeComponent,
    ContactComponent,
    DashboardComponent,
    DealerComponent,
    DealereditComponent,
    HomeComponent,
    LoginComponent,
    OtdbComponent,
    PagesComponent,
    ReportsComponent,
    SalestrainingComponent,
    UserComponent,
    UsereditComponent,
    CropavatarComponent,
    DealerselectComponent,
    PageComponent,
    SaleschartComponent,
    UserselectComponent,
    SeminarsComponent,
    GalleryComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    ImageCropperModule,
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
