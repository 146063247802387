import { Injectable } from '@angular/core';
import {Subscription, BehaviorSubject} from 'rxjs/Rx';

declare type TouchSignaler = (hasTouch: boolean) => void;

@Injectable({ providedIn: 'root'})
export class RRServiceTouch {

  private subject:BehaviorSubject<boolean> = null;

  constructor() { }

  subscribeHasTouch( signal: TouchSignaler ): Subscription
  {
    if( !this.subject )
    {
      var RR_HASTOUCH_KEY = 'rr.service.touch.hastouch.key';
      var hasTouch = localStorage.getItem(RR_HASTOUCH_KEY) ? true : false;
      var subject = this.subject = new BehaviorSubject<boolean>(hasTouch);

      if( !hasTouch )
      {
        document.body.addEventListener('touchstart', function setHasTouch (e) {
          // Once we've detected this event we save that knowledge
          // then remove the listener
          localStorage.setItem(RR_HASTOUCH_KEY, 'true');
          subject.next(true);
          document.body.removeEventListener('touchstart', setHasTouch);
        }, false);
      }

    }
    return this.subject.subscribe( signal );
  }

}
