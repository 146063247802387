import {Component, OnInit, OnDestroy, OnChanges, AfterViewInit
  , Input, Output, EventEmitter, ElementRef} from '@angular/core';

import { NgForm,FormBuilder, FormGroup, FormControl }    from '@angular/forms';

import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subscriber } from 'rxjs/Subscriber';
import * as _ from 'lodash';

/*
 * Services
 */
import { RRServiceUserState } from "../../services/rr.service.userstate";
import { RRServiceDealerList } from "../../services/rr.service.dealerlist";
import { RRServiceUtil } from "../../services/rr.service.util";
import { Alert } from "../../services/alert";
import { DealerselectComponent } from "../../helpers/dealerselect/dealerselect.component";


declare var $:any;

@Component({
  selector: 'app-dealer',
  templateUrl: './dealer.component.html',
  styleUrls: ['./dealer.component.css']
})
export class DealerComponent implements OnDestroy, OnChanges, AfterViewInit {

    waiting:boolean;
    dateElem:any;
    onDateChange:any;


    @Input('multiEdit') multiEdit:boolean;
    @Input('dealer') dealer:RRData.Dealer;
    @Input('dealerClone') dealerClone:RRData.Dealer;



    constructor(
      private _alert:Alert,
      private _rrServiceUtil:RRServiceUtil,
      private _rrServiceUserState:RRServiceUserState,
      public _rrServiceDealerList:RRServiceDealerList)
    {
    }

    ngAfterViewInit() {
      this.dateElem = this._rrServiceUtil.dateOnInit("dealersetup-expires",
        (iDate:number) =>
          {
            console.log('Dealer.onDateChange(' + iDate + ')');
            this.dealer.expires = iDate;
          }
      );
      this.setDate();
      console.log('Dealer.ngAfterViewInit()', this.dealer, this.dateElem);
    }

    ngOnDestroy() {
      this._rrServiceUtil.dateOnDestroy(this.dateElem);
      console.log('Dealer.ngOnDestroy()');
    }

    ngOnChanges(changes:any) {
      console.log('Dealer.ngOnChanges()',changes);
      this.setDate();
    }

    private setDate()
    {
      console.log('Dealer.setDate()',this.dateElem, this.dealer.expires);
      this._rrServiceUtil.setDate( this.dateElem, this.dealer.expires );
    }

    colClass(name:string)
    {
      if( this.dirty() && this.canDelete() )
        return 'col-sm-12 col-md-6 col-lg-3' + ((name == 'save') ? ' col-lg-offset-3' : '');
      else
        return 'col-sm-12';
    }


    dirty()
    {
      /* this.dealer && this.dealerClone && */
      return !_.isEqual(this.dealer, this.dealerClone);
    }

    canDelete()
    {
      return this.multiEdit &&
        this.dealer.dealershipId > 0 &&
        this._rrServiceUserState.getDealershipId() != this.dealer.dealershipId;
    }

    private doFunc(name:string)
    {
      this.waiting = true;
      let func = this.dealer.dealershipId ? "Update" : "Add";
      let config = {
        id:'dealer-alert',
        alertTypeConfig:Alert.SUCCESS,
        message:'Success!',
        title:((name == "deleteDealer") ? "Delete" : func) +  " Dealer Entry",
        timeout:0,
      };

      this._rrServiceDealerList[name](this.dealer)
        .finally(() => {
            this.waiting = false;
          }).subscribe(
            result => {
              this._alert.create(config);
            },
            errMsg => {
              config.alertTypeConfig = Alert.ERROR;
              config.message = errMsg.toString();
              this._alert.create(config);
            }
          );
    }

    selectDealerDow(value:string)
    {
      if( value )
      {
        this.dealer.startDow = parseInt(value);
        console.log("Converting starting dow to int: ", this.dealer.startDow );
      }
    }

    delete() {
      this._alert.create( {
        id:'dealer-alert',
        alertTypeConfig:Alert.WARNING,
        message:
            `Deletion of a dealer is a serious action. It CANNOT be undone.
             ALL data associated with the dealer will be deleted. This means
             all users, all users otdb entries and all users action logs.
             Be very, very certain before you continue. If you decide you do not
             wish to delete this dealer then click the 'Cancel' button`,
        title:"Confirm Delete!",
        alertTypeButton: Alert.ERROR,
        onSuccess: () => this.doFunc('deleteDealer'),
        successButton: 'Delete Dealer',
      } );
    }

    onSubmit() { this.doFunc('updateDealer'); }

}
