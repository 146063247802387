import {Component, OnInit, OnDestroy, AfterViewChecked,
  Input, ElementRef, ViewChildren, QueryList} from '@angular/core';
import {NgForm,FormBuilder,FormGroup,FormControl}    from '@angular/forms';
import {Observable} from 'rxjs/Observable';
import {Subscription} from 'rxjs/Subscription';
import {Subscriber} from 'rxjs/Subscriber';


/*
 * Services
 */
import {RRServiceUserState} from "../../services/rr.service.userstate";
import {RRServiceDealerList} from "../../services/rr.service.dealerlist";

import { DealerComponent } from "../dealer/dealer.component";
import { DealerselectComponent } from "../../helpers/dealerselect/dealerselect.component";
import { UserselectComponent } from "../../helpers/userselect/userselect.component";

declare var $:any;
import * as _ from 'lodash';



@Component({
  selector: 'app-dealeredit',
  templateUrl: './dealeredit.component.html',
  styleUrls: ['./dealeredit.component.css']
})
export class DealereditComponent implements OnInit {

  editDealer: RRData.Dealer;
  editDealerClone:RRData.Dealer;
  addDealer:RRData.Dealer;
  addDealerClone:RRData.Dealer;
  dealer:RRData.Dealer;
  multiEdit:boolean;
  subscription:Subscription;
  isAddDealer:boolean;
  dealerClone:RRData.Dealer;


  constructor(private _rrServiceDealerList:RRServiceDealerList,
    private _rrServiceUserState:RRServiceUserState)
  {
    this.next(null);
    this.subscription =
      _rrServiceDealerList.subscribeSelectedDealer(
          dealer => this.next(dealer) );
  }

  private next(dealer:RRData.Dealer)
  {
    this.addDealer = {
      dealershipId: 0, autoType: '', login: '', modules: '', maxUsers: 0,
      expires: 0, startDow: 0, name: '' };
    this.addDealerClone = _.clone(this.addDealer);

    this.multiEdit = this._rrServiceUserState.isSystemAdmin();
    this.editDealer = dealer;
    this.editDealerClone =  _.clone(this.editDealer);
    this.setAddDealer(dealer==null);
    this._rrServiceUserState.tooltipHide();
    console.log("DealerEdit.subscription.next",dealer);

  }

  ngOnInit() {
    console.log('DealerEdit.ngOnInit()');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    console.log('DealerEdit.ngOnDestroy()')
  }

  private updateClass(id:string, className:string, isAdd:boolean)
  {
    var elem = document.getElementById(id);
    if( elem )
    {
      try {
        if( isAdd ) elem.classList.add(className);
        else elem.classList.remove(className);

      } catch( err ) {
        console.warn("DealerEdit.updateClass error:", elem, err);
      }
    }
  }

  private setAddDealer(isAddDealer:boolean)
  {
    this.isAddDealer = isAddDealer;
    this.updateClass('dealer-edit-add', 'active', this.isAddDealer);
    this.updateClass('dealer-edit-edit', 'active', !this.isAddDealer);
    this._rrServiceUserState.tooltipHide();
    if( this.isAddDealer )
    {
      this.dealer =  this.addDealer;
      this.dealerClone = this.addDealerClone;
    } else {
      this.dealer =  this.editDealer;
      this.dealerClone = this.editDealerClone;
    }
  }

}
