import {
  Component, OnInit, Output, EventEmitter
  // ,OnDestroy,Input
  } from '@angular/core';

@Component({
  selector: 'app-cropavatar',
  templateUrl: './cropavatar.component.html',
  styleUrls: ['./cropavatar.component.css']
})

export class CropavatarComponent implements OnInit
{
  //@Input('modalId') modalId:string; // stored value
  @Output() avatarChange = new EventEmitter<string>(); // an event
  imageChangedEvent: any = '';
  image: string;

  constructor() {
    console.log("CropavatarComponent.constructor");
  }

  fileChangeEvent(event: any): void {
    console.log("CropavatarComponent.fileChangeEvent",event);
    this.imageChangedEvent = event;
  }

  imageCropped(image: string) {
    this.image = image
    console.log("CropavatarComponent.fileChangeEvent");
  }

  save() {
    console.log("CropavatarComponent.save", this.image ? "image" : "none");
    if( this.image )
      this.avatarChange.emit(this.image);
  }

  ngOnInit() {
    console.log("CropavatarComponent.ngOnInit()");
  }
}
