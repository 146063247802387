import { Injectable } from '@angular/core';
import * as _ from 'lodash';


@Injectable({providedIn: 'root'})

export class RRServiceVideoData {
  private videoData:any;
  private accelIdx:string;

  private srcDir(dir:string, name:string, ext:string ): string
  {
    return '/protected/video/' + dir + '/' + name + '/' + name + '.' + ext;
  }

  execute(vid:any, name:string) {
    if ( vid && vid['funcs']  ) {
      let f = vid['funcs'][name];
      if( typeof f === 'function' )
      {
        console.log('execute', name, vid);
        f();
      }
    }
  }

  pauseAll(data?:any)
  {
    _.each(this.videoData, (tld) => {
      _.each(tld, (vid) => {
        if( vid && vid != data && vid['funcs'] )
          vid['funcs'].pause();
      });
    });
  }

  vid(cat:string, name:string):any
  {
      let o = this.videoData[cat];
      return o ? o[name] : undefined;
  }

  sources(cat:string,name:string,isAudio:boolean): Array<any>
  {
    let vid = this.vid(cat,name);
    return vid ? vid[isAudio ? 'audioSources' : 'videoSources'] : undefined;
  }

  poster(cat:string, name:string):string
  {
    let vid = this.vid(cat,name);
    return vid ? vid['poster'] : undefined;
  }

  setIdx(idx:string)
  {
    this.accelIdx = idx;
  }

  private buildData(dir:string, info:any, ...names:string[]) : any
  {
    let ret = {};
    _.each(names,(name) => {
      let tlo = {
        poster: this.srcDir(dir, name, 'jpg' ),
        logActivity: (name != 'rr_tour') ? true : false,
      }; // Top Level Object
      ret[name] = tlo;
      _.each(info,(infoSrcArray,sourceArrayType) => {
        let sa = []; // Source array
        _.each(infoSrcArray,(infoSrc) => {
          let src = {
              src: this.srcDir(dir, name, infoSrc.ext ),
              type: infoSrc.type,
              codecs: infoSrc.codecs,
            };
          sa.push(src);
        });
        tlo[sourceArrayType] = sa;
      });
    });
    return ret;
  }

  constructor()
  {
    let info =
      { videoSources : [
          { ext: 'm3u8',  type: 'video/m3u8', codecs: 'avc1.42c00d,mp4a.40.2' },
          { ext: 'mpd',   type: 'video/mpd',  codecs: 'avc1.42c00d,mp4a.40.2' },
          { ext: 'mp4',   type: 'video/mp4',  codecs: 'avc1.42E01E,mp4a.40.2' },
          { ext: 'webm',  type: 'video/webm', codecs: 'vp8,vorbis' },
          { ext: 'ogv',   type: 'video/ogg',  codecs: 'theora,vorbis' },
        ],
        audioSources : [
          { ext: 'aac',  type: 'audio/aac', codecs: null },
          { ext: 'ogg',  type: 'audio/ogg',  codecs: null }
        ]
      };

    this.videoData = {
      sales:  this.buildData('sales',  info, 'td', 'cs', 'pa', 'ce', 'uce', 'pg',
                  'introp1', 'introp2', 'introp3', 'ho', 'del', 'sel', 'close', 'rr_tour' ),
      sales2: this.buildData('sales2', info, 'p2_intro', 'p2_power_greeting', 'p2_pre_appraisal', 'p2_selection_alternate_selection',
                  'p2_emotional_test_drive', 'p2_hood_ornament', 'p2_dealership_tour', 'p2_service_introduction', 'p2_used_car_evaluation',
                  'p2_comparison_example', 'p2_hfo_new', 'p2_hfo_used', 'p2_write_up', 'p2_dar', 'p2_question_mark','p2_white_horse'),
      accel: {
        accel: {
          videoSources: [ {
            src: '/protected/video/accel/cur/accel_video.mp4',
            type: 'video/mp4',
            codecs: 'avc1.42E01E,mp4a.40.2'
          }],
          isAccelVideo: true,
          logActivity: true
        }
      },
      accelGallery: {

      },
    };
  }

  public AccelGalleryName( idx:number ) { return "rra" + idx; }

  public setAccelGallery( accelJSON:any ) {

    accelJSON.Videos.forEach( vid => {
      let name = this.AccelGalleryName(vid.Idx)
      this.videoData.accelGallery[name] =
      {
        videoSources: [ {
          src: '/protected/video/accel/' + name + '/accel_video.mp4',
          type: 'video/mp4',
          codecs: 'avc1.42E01E,mp4a.40.2'
        }],
        isAccelVideo: true,
        logActivity: false,
        // poster: 'WhatMeWorry',
      }

    } );
  }
}
