import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subscriber } from 'rxjs/Subscriber';

declare var $:any;

/*
 * Services
 */
import {RRServiceUserState} from "../../services/rr.service.userstate";
import {RRServiceUtil} from "../../services/rr.service.util";
import {Alert} from "../../services/alert";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  waiting:boolean;
  values:any;
  keys:Array<string>;
  subscription: Subscription;

  constructor(
    private _alert:Alert,
    private util:RRServiceUtil,
    private _rrServiceUserState:RRServiceUserState)
  {
    this.keys = ['dealer', 'username', 'password'];
    this.values = {};
    this.keys.forEach(key => this.values[key] = '');
    this.values['remember'] = false;
    console.log("Login(): ", this.values);
  }

  ngOnInit() {
    console.log('Login.ngOnInit().subscribe', this.values);
    this.subscription = this._rrServiceUserState.subscribe(
      resp => console.log('Dealer.ngOnInit().subscribe().next: ' + resp)
    );
  }

  ngOnDestroy() {
    console.log('Login.ngOnDestroy().unSubscribe')
    this.subscription.unsubscribe();
  }


  onSubmit(event:any): void {
    console.log('Login.onSubmit().before', this.values);
    this.keys.forEach(key => {
      let val=$( '#login-' + key ).val();
      if( val ) this.values[key] = val;
    });
    console.log('Login.onSubmit().after', this.values);
    this.waiting = true;
    this.util.blurButtons(event);
    this._rrServiceUserState.login(this.values)
      .finally(() => this.waiting = false )
      .subscribe(
        result => console.log("Login Success", result, event),
        errMsg =>
          this._alert.create(
            {
              id:'login-alert',
              alertTypeConfig:Alert.ERROR,
              message:errMsg.toString(),
              title:'Login Failed'
            })
        );
  }

}
