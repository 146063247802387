import { Injectable } from '@angular/core';
import {Subscription, BehaviorSubject, Observable} from 'rxjs/Rx';
import {RRServiceServer} from "./rr.service.server";
import {RRServiceUserState} from "./rr.service.userstate";
import * as _ from 'lodash';

declare type Signaler = (value: Array<RRData.Dealer>) => void;
declare type DealerSignaler = (value: RRData.Dealer) => void;

@Injectable({ providedIn: 'root'})
export class RRServiceDealerList {

  private dealers: Array<RRData.Dealer>;
  private curDealer: RRData.Dealer;
  private subscription: Subscription;
  private dealerListSubject:BehaviorSubject<Array<RRData.Dealer>>;
  private selectedDealerSubject:BehaviorSubject<RRData.Dealer>;


  constructor(public _rrServiceUserState:RRServiceUserState) {
    this.dealers = new Array<RRData.Dealer>();
    this.dealerListSubject = new BehaviorSubject<Array<RRData.Dealer>>(this.dealers);
    this.selectedDealerSubject = new BehaviorSubject<RRData.Dealer>(null);

    // Technically since this is a singleton instance
    // we will never need to hold onto the subscription
    // in order to unsubscripe - but I leave it here in the case
    // we go to a child injector model
    this.subscription =
      _rrServiceUserState.subscribe( userstate => {
        if( !userstate ) this.clearDealers();
        else this.fetchDealers();
      });
  }

  private publish() {
    if( this.dealers.length )
        this.dealers = _.sortBy(this.dealers, 'login' );
    this.dealerListSubject.next(_.cloneDeep(this.dealers));
  }

  private publishDealer() {
    this.selectedDealerSubject.next(this.curDealer);
  }

  subscribeDealerList( signal: Signaler ): Subscription
  {
    return this.dealerListSubject.subscribe( signal );
  }

  subscribeSelectedDealer( signal: DealerSignaler ): Subscription
  {
    return this.selectedDealerSubject.subscribe( signal );
  }

  private clearDealers() {
    this.curDealer=null;
    this.dealers.length=0;
    this.publish();
    this.publishDealer();
  }
  getDealers() { return this.dealers; }
  getCurDealer() { return this.curDealer; }

  setCurDealer(dealershipId:number,ignorePublish?:boolean) {
    this.curDealer = this.getDealer(dealershipId);
    if( !ignorePublish ) this.publishDealer();
  }

  isMultiDealer() { return this.dealers.length > 1 ? true : false; }

  getDealer(dealershipId:number):RRData.Dealer {
    return _.find(this.dealers, {dealershipId:dealershipId});
  }

  showDealer(dealer?:RRData.Dealer)
  {
    if( !dealer ) dealer = this.getCurDealer();
    if( !dealer ) return '';
    return dealer.login;
  }

  private apiCall(id?:number) { return 'dealer' + (id ? ('/' + id) : ''); }
  private fetchDealers():void
  {
    this._rrServiceUserState._rrServiceServer.get(this.apiCall())
      .map( ret => <Array<RRData.Dealer>>ret.result )
      .subscribe(
          ret => {
            this.dealers = ret;
            // Make sure we can find either selected dealer or my dealer in list.
            if( this.curDealer ) this.setCurDealer(this.curDealer.dealershipId);
            // If couldn't find current CurDealer then try my Dealer
            if( !this.curDealer ) this.setCurDealer(this._rrServiceUserState.getDealershipId(),true);
            if( this.curDealer ) {
              this.publish();
              this.publishDealer();
            }
            else this.clearDealers();
          },
          err => {
            this.clearDealers();
            console.error('RRServiceDealerList.fetchDealers().catch(): ', err);
          }
      );
  }


  updateDealer(dealer:RRData.Dealer): Observable<RRData.Dealer>
  {
    //       { body:JSON.stringify(dealer) }
    return this._rrServiceUserState._rrServiceServer.post(
      this.apiCall(dealer.dealershipId), { body: JSON.stringify(dealer) } )
      .map( ret => <RRData.Dealer>ret.result ) // Map result to dealer
      .do( dealer =>
        { // update my local cache
          var idx = _.findIndex(this.dealers, d => d.dealershipId == dealer.dealershipId );
          if( idx >= 0 ) this.dealers[idx] = dealer;
          else this.dealers.push(dealer);
          this.curDealer = dealer;
          this.publishDealer();
          this.publish();
        });
  }

  deleteDealer(dealer:RRData.Dealer): Observable<RRData.Dealer>
  {
    return this._rrServiceUserState._rrServiceServer.delete(
      this.apiCall(dealer.dealershipId))
      .map( ret => <RRData.Dealer>ret.result ) // Map result to dealer
      .do( dealer => {
        _.remove(this.dealers,
            d => (dealer && (d.dealershipId == dealer.dealershipId)));
        this.publish();
        if( this.curDealer && this.curDealer.dealershipId == dealer.dealershipId )
          this.setCurDealer(this._rrServiceUserState.getDealershipId());
      });
  }

}
