/*
 * Angular
 */
import { OnInit, OnDestroy, AfterViewInit, Component } from '@angular/core';
import { NgForm,FormBuilder,FormGroup,FormControl }    from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subscriber } from 'rxjs/Subscriber';

/*
 * Services & Helpers
 */
import { RRServiceServer } from "../../services/rr.service.server";
import { RRServiceUserState } from "../../services/rr.service.userstate";
import { Alert } from "../../services/alert";

import { PageComponent } from '../../helpers/page/page.component'

import * as _ from 'lodash';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css']
})
export class PagesComponent implements AfterViewInit
{

  pages:Array<RRData.Page>;
  dispPages:Array<RRData.Page>;

  pageResp:RRData.PageResponse;
  pageReq:RRData.PageRequest;
  categories:Array<String>;
  requestPending:boolean;
  finished:boolean;
  asAdmin:boolean;

  constructor(
    private _alert:Alert,
    private _rrServiceServer:RRServiceServer,
    public _rrServiceUserState:RRServiceUserState)
  {
    console.log("Pages");
    this.pageReq = {
      category: 'Page',
      contentFilter: '',
      titleFilter: '',
      continuation: false,
      admin: false,
      start: 0,
      length: 24
    };
    this.asAdmin=false;
    this.requestPending = false;
    this.categories = [ 'Manual', 'Page', 'Accel', 'Poster', 'Admin', 'Trash' ];
  }

  private scrollEvent(event)
  {
    if( this.finished || this.requestPending ) return;
    console.log("Pages.scrollEvent()", event);
    var ele = event.target;
    var atBottom = ele.scrollHeight - ele.scrollTop - ele.clientHeight;
    if( atBottom <  290 )
    {
      console.log("Pages.scrollEvent()", ele.scrollHeight, ele.scrollTop, atBottom);
      this.sendRequest();
    }
  }

  ngAfterViewInit() {
    this.selectCategory('Page');
    console.log("Pages.ngAfterViewInit():");
  }

  update()
  {
    console.log('Pages.update()');
    this.doUpdate(null,0);
  }

  sendRequest()
  {
    console.log('Pages.sendRequest()', 'finished:', this.finished, 'pageReq:', this.pageReq, "asAdmin:", this.asAdmin);
    if( !this.finished )
    {
      this.requestPending=true;
      this.pageReq.start = this.pages.length;
      this.pageReq.admin = this.asAdmin;
      this._rrServiceServer.get('page',
        {search:{pageReq: JSON.stringify(this.pageReq)} } )
        .map( ret => <RRData.PageResponse>ret.result )
        .subscribe(
          result => {
            this.pageResp = <RRData.PageResponse>result;
            var pages = this.pageResp.pages;
            if( pages && pages.length )
            {
              var myMap = new Map();
              this.pages.forEach(page => myMap.set( page.pageId, true ) );
              this.pageResp.pages.forEach( page => {
                if ( !(myMap.get(page.pageId)===true) )
                  this.pages.push(page)
              } );
              this.pageReq.continuation = true;
            } else {
              this.finished = true;
            }
            this.filterPages();
            this.requestPending=false;
          },
          errMsg => {
            this.requestPending=false;
            this._alert.create(
            {
              id:'pages-alert',
              alertTypeConfig:Alert.ERROR,
              message:errMsg.toString(),
              title:"Page Request",
            })
	  }
        );
    }
  }

  newRequest(): void {
    this.pages = new Array<RRData.Page>();
    this.pageReq.continuation = false;
    this.finished = false;
    this.sendRequest();
  }

  selectCategory(value)
  {
    if( value == 'Trash' ) return; // Trash is not true category
    _.each(this.categories, category =>
      {
        this.updateClass('pages-category-' + category, 'active', category == value );
      }
    );
    this.pageReq.category = value;
    console.log("Pages.selectCategory()", value);
    this.newRequest();
  }

  selectTitleFilter(value)
  {
    this.pageReq.titleFilter = value;
    console.log("Pages.selectTitleFilter()", value);
    this.newRequest();
  }

  selectContentFilter(value)
  {
    this.pageReq.contentFilter = value;
    console.log("Pages.selectContentFilter()", value);
    this.newRequest();
  }

  private updateClass(id:string, className:string, isAdd:boolean)
  {
    var elem = document.getElementById(id);
    if( elem )
    {
      if( isAdd ) elem.classList.add(className);
      else elem.classList.remove(className);
    }
  }

  doUpdate(category:string,pageId:number)
  {
    var params = {};
    var isDelete = (category == 'Trash');
    category = category.toUpperCase();
    params['category'] = category;
    params['pageId'] = pageId;
    var title;
    var page = _.find(this.pages,{pageId:pageId});
    var button;
    var message = `You are about to delete the document defined above. While this
      can be manually retrieved by Mark please make sure you are certain.
      If you decide you do not wish to do delete the document at this time then
      click the 'Cancel' button`;
    if( pageId < 0 ) {
      button = (isDelete ? 'Delete' : 'Update' );
      title =  button + ' Document: ' + (page ? page.title : '' );
      if( !isDelete )
        message = `You are about to change the state of the manual defined above.
          If you are moving it to Admin section then it will be hidden from all
          but system adminsitrators. If you are moving it anywhere else then it
          will become available to all users. If you decide you do not wish to
          change the manual state at this time then click the 'Cancel' button`;
    } else {
      button = (isDelete ? 'Delete' : 'Move' );
      title =  button + ' Document: ' + (page ? page.title : '' );
      if( !isDelete )
        message = `You are about to move the document defined above. It will be
          moved from ` + (page ? page.category : 'unknown') + ' to '  + category
          + `. If you are moving it to the Admin section then it will be hidden from
          all but system adminsitrators. If you are moving it anywhere else then it
          will become available to all users. If you decide you do not wish to
          change the document location at this time then click the 'Cancel' button`;
    }

    this._alert.create( {
      id:'pages-alert',
      alertTypeConfig:Alert.WARNING,
      message: message,
      title: title,
      alertTypeButton: Alert.ERROR,
      onSuccess: () => {
        // In here we have been called by user confirming the action
        this.requestPending=true;
        this._rrServiceServer[isDelete ? 'delete' : 'post']( 'page', {search:params } )
          .finally(() => {
            this.requestPending=false;
          }).subscribe(
            result => this.newRequest(),
            errMsg =>
              this._alert.create(
                {
                  id:'pages-alert',
                  alertTypeConfig:Alert.ERROR,
                  message:errMsg.toString(),
                  title:"Drag & Drop",
                })
          );
      },
      successButton: button,
    } );
  }

  drop(category:string,$event:any)
  {
    if(this.asSystemAdmin())
    {
      $event.preventDefault();
      console.log("Pages.drop():",category,$event)
      try {
        var x = $event.dataTransfer.getData("text/plain");
        if( x )
        {
          var pageId = parseInt(x);
          this.doUpdate(category,pageId);
        }
      } catch (err) {
        console.error( "Pages.drop() - Failed to do update", err)
      }
    }
  }

  dragEnterOver(type:string, $event:any):boolean
  {
    if(this.asSystemAdmin())
    {
      if( type=='enter' )
        event.preventDefault();
      return type=='enter';
    }
  }

  dragStart(pageId:number,$event)
  {
    $event.dataTransfer.setData("text/plain", "" + pageId);
    console.log("Page.drag(" + pageId + ")", $event);
  }


  asSystemAdmin():boolean
  {
    return this.asAdmin && this._rrServiceUserState.isSystemAdmin();
  }
  canViewCategory(category:string):boolean
  {
    if( this.asSystemAdmin() ) return true;
    if( (category == 'Admin') || (category == 'Trash') ) return false;
    return true;
  }

  selectAdminState($event)
  {
    this.asAdmin = !this.asAdmin;
    $event.currentTarget.innerHTML = this.asAdmin ? "View Normal" : "View Admin";
    this.newRequest();
  }

  filterPages()
  {
    if( this.asAdmin )
      this.dispPages = this.pages;
    else
      this.dispPages = _.filter(this.pages, {admin:false} );
  }

  adminFunction(func:string)
  {
    var title;
    var button = func;
    var message;
    switch(func)
    {
      case 'Update':
        title = 'Update Document Repository'
        message = `You are about to update the websites copy of the document
          repository. This is required to synchronize external changes with the
          website. It is generally safe to do but errors should be resolved
          before continuing.`;
          break;

      case 'Scan':
        title = '(Re)Scan Document Repository'
        message = `This will rescan the document repository and synchronize the
          database with changes applied to the local system. Note if no changes
          were reported by the Update you need not do this since individual drag
          and drop operations keep the database and repository synchronized.`;
          break;

      case 'Commit':
        title = 'Commit Document Changes'
        message = `This will commit your changes to the document repository.
          This will make them available to external watchers.`;
          break;

      case 'Revert':
        title = 'Warning --- Revert Document Changes --- Warning'
        message = `This will undo whatever changes you have made since
          the last successful commit. It is not reccomended except as a last
          resort if you cannot get in contact with IT and the documentation
          repository is broken.`;
          break;

    }

    message += ` Please coordinate with IT until you have confidence in the
    process. Any problems can be corrected but they make take some time so please
    be careful.  If you decide you do not wish to do this then click the 'Cancel'
    button`;

    this._alert.create( {
      id:'pages-alert',
      alertTypeConfig:Alert.WARNING,
      message: message,
      title: title,
      alertTypeButton: Alert.ERROR,
      onSuccess: () => {
        // In here we have been called by user confirming the action
        this.requestPending=true;
        this._rrServiceServer.post( 'page', {search:{ func:func } } )
          .finally(() => {
            this.requestPending=false;
          }).subscribe(
            result =>
              this._alert.create(
                {
                  id:'pages-alert',
                  alertTypeConfig:Alert.INFO,
                  message:result.result,
                  title:"Admin Result",
                  alertTypeButton: Alert.ERROR,
                  onSuccess: () => this.newRequest()
                }),
            errMsg =>
              this._alert.create(
                {
                  id:'pages-alert',
                  alertTypeConfig:Alert.ERROR,
                  message:errMsg,
                  title:"Admin Request",
                })
          );
      },
      successButton: button,
    } );

  }
}
