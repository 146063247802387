import {Component, OnInit, OnDestroy,
    OnChanges, Input, ElementRef, SimpleChange } from '@angular/core';

import {Subscription} from 'rxjs/Rx';
import {RRServiceVideoData} from "../../services/rr.service.videodata"

declare var Chart:any;


@Component({
  selector: 'app-saleschart',
  templateUrl: './saleschart.component.html',
  styleUrls: ['./saleschart.component.css']
})
export class SaleschartComponent implements OnInit, OnDestroy, OnChanges {

  @Input('data') data:any; // stored value
  @Input('idx') idx:number; // stored value
  private ctx:any;
  private chart:any;

  // events
  chartClicked(e:any) {
    console.log(e);
  }
  chartHovered(e:any) {
    console.log(e);
  }

  constructor(private myElement:ElementRef) {}

  destroyChart() {
    if( this.chart )
    {
      this.chart.destroy();
      this.chart = null;
    }
  }

  drawChart() {
    if( this.ctx && this.data )
    {
      var data = null;
      var label = null;
      switch( this.idx )
      {
        case 0:
          data = this.data.userData;
          label = "User Sales";
          break;
        case 1:
          data = this.data.teamData;
          label = "Team Sales";
          break;
        default:
          data = this.data.dealerData;
          label = "Dealer Sales";
          break;
      }
      if( data )
      {
        this.chart = new Chart(this.ctx, {
          type: 'bar',
          data: {
            labels:this.data.periodNames,
            datasets: [
                {
              			backgroundColor: "rgba(102,17,17,0.9)", // String  or array - the bar color
              			borderColor: "rgba(240,217,181,1)",    // String or array - bar stroke color
              			borderWidth: 1,  // Number or array - bar border width
              			hoverBackgroundColor: "rgba(102,17,17,1)", // String or array - fill color when hovered
              			hoverBorderColor: "rgba(240,217,181,1)", // String or array - border color when hovered
                    data: data,
                },
              ],
            },
          options: {
              maintainAspectRatio: true,
              title: {
                  display:true,
                  fontColor: '#000',
                  fontFamily: "'Helvetica Neue'",
                  fontSize: 14,
                  fontStyle: 'bold',
                  padding:10,
                  text: label,
                },
              legend: {
                  display: false,
                },
              scales: {
                  xAxes: [{
                      display: true,
                      gridLines: {
                          display:true,
                          color: "rgba(198,198,198,1)",
                          zeroLineColor: "rgba(198,198,198,1)",
                        },
                      scaleLabel: {
                          display:true,
                          labelString:'Period',
                          fontColor: '#000',
                          fontFamily: "'Helvetica Neue'",
                          fontSize: 12,
                          fontStyle: 'normal',
                        },
                      ticks: {
                        display:true,
                        fontColor: '#000',
                        fontFamily: "'Helvetica Neue'",
                        fontSize: 12,
                        fontStyle: 'normal',
                      },
                    }],
                  yAxes: [{
                      display: true,
                      gridLines: {
                          display:true,
                          color: "rgba(198,198,198,1)",
                          zeroLineColor: "rgba(198,198,198,1)",
                        },
                      scaleLabel: {
                        display:true,
                        labelString:'Cars Sold',
                        fontColor: '#000',
                        fontFamily: "'Helvetica Neue'",
                        fontSize: 12,
                        fontStyle: 'normal',
                      },
                      ticks: {
                        display:true,
                        fontColor: '#000',
                        fontFamily: "'Helvetica Neue'",
                        fontSize: 12,
                        fontStyle: 'normal',
                      },
                    }],
                }
            }
        });
      }
    }
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}) {
    //this.ctx = document.getElementById("myChart").getContext("2d");
    this.destroyChart();
    this.drawChart();
    console.log('SalesChart.ngOnChanges:',changes);
  }

  ngOnInit() {
    try {
      this.ctx = this.myElement.nativeElement.firstElementChild.getContext("2d");
    } catch( err ) {
      console.warn("SalesChart.ngOnInit() - error", err);
    }
    this.drawChart();
    console.log("SalesChart.ngOnInit()",this.ctx, this.chart);
  }

  ngOnDestroy() {
    this.destroyChart();
    console.log("SalesChart.ngOnDestroy()");
  }

}
