import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';

import {RRServiceUserList} from "../../services/rr.service.userlist";
import {RRServiceUserState} from "../../services/rr.service.userstate";

import * as $ from 'jquery';
import * as _ from 'lodash';

@Component({
  selector: 'app-userselect',
  templateUrl: './userselect.component.html',
  styleUrls: ['./userselect.component.css']
})
export class UserselectComponent implements OnInit, OnDestroy {

  private users: Array<RRData.User>;
  private userId:number;
  private userListSubscription: Subscription;
  private selectedUserSubscription: Subscription;

  @Input('allowEmpty') allowEmpty:boolean;
  @Input('isHidden') isHidden:boolean;

  constructor(
    private _rrServiceUserState:RRServiceUserState,
    private _rrServiceUserList:RRServiceUserList )
  {
    this.users = _rrServiceUserList.getUsers();
    this.userId = 0;
  }

  private setUserId( userId:number )
  {
    this.userId=userId;
  }

  ngOnInit()
  {
    this.userListSubscription =
      this._rrServiceUserList.subscribeUserList(
        users => {
          this.users = users;
          var userId = 0;
          if( users && users.length > 0 )
          {
            var user = _.find(users, {userId:this.userId} );
            if( !user && !this.allowEmpty)
            {
              user = _.find(users, {userId:this._rrServiceUserState.getUserId()} );
              if( !user ) user = users[0];
            }
            if( user ) userId = user.userId;
          };
          this.selectUser( '' + userId );
        });
    this.selectedUserSubscription =
      this._rrServiceUserList.subscribeSelectedUser(
        user => this.userId=user ? user.userId : 0);
    console.log('UserSelect.ngOnInit()');
  }

  ngOnDestroy() {
    console.log('UserSelect.ngOnDestroy()');
    this.userListSubscription.unsubscribe();
    this.selectedUserSubscription.unsubscribe();
  }

  selectUser(value:string)
  {
    var userId:number;
    try {
      userId = parseInt(value,10);
    } catch( err ) {
      console.error('UserSelect.selectUser() value:', value, err);
      userId = 0;
    }

    console.log('UserSelect.selectUser() userId:', userId);
    this._rrServiceUserList.setCurUser(userId);
  }

}
