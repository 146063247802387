import { Injectable } from '@angular/core';
declare var $:any;
import * as _ from 'lodash';

declare type AlertSignaler = () => void;
interface AlertConfig {
    id:string;                // The id of the pre configured element to locate the alert
    alertTypeConfig:string;   // The alert style to use for alert box
    message:string;           // The data in the alert box
    title:string;             // the title to put in alert box

    alertTypeButton?:string;  // The alert style to use for OK button
    onSuccess?:AlertSignaler; // Callback if user says OK
    onCancel?:AlertSignaler;  // Callback if user says Cancel
    successButton?:string;    // String to put in success button (Ok=default)
    cancelButton?:string;     // String to put in success button (Cancel=default)
    timeout?:number;          // if non zero then will make alert go away in that many ms

};

@Injectable({ providedIn: 'root'})
export class Alert
{
  static SUCCESS  = 'success';
  static INFO     = 'info';
  static WARNING  = 'warning';
  static ERROR    = 'danger';

  private alertTemplate:string =`
    <div class="alert alert-{{alertTypeConfig}} alert-dismissible fade in out show" role="alert">
      <button type="button" class="close myalert-closeButton" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <h4>{{title}}</h4>
      {{message}}
      <p>
        <button {{hidden-success}} type="button" class="btn btn-{{alertTypeButton}} myalert-OkButton">{{successButton}}</button>
        <button {{hidden-cancel}} type="button" class="btn btn-default myalert-closeButton">{{cancelButton}}</button>
      </p>
    </div>
  `;

  private destroy($alertDiv:any, config?:AlertConfig, isOk?:boolean)
  {
    // Call delagates as required
    if( config )
    {
      if( isOk )
      {
        if( config.onSuccess ) config.onSuccess();
      } else {
        if( config.onCancel ) config.onCancel();
      }
    }
    // Cleanup
    $alertDiv.find( ".myalert-OkButton" ).off( "click");
    $alertDiv.find( ".myalert-closeButton" ).off( "click" );
    $alertDiv.empty();
  }

  private setButton(htmlStr:string, hideStr:string, buttonName:string, buttonVal:string) : string
  {
    if( buttonVal )
    {
      return htmlStr
        .replace(hideStr, '')
        .replace(buttonName, buttonVal);
    } else {
      return htmlStr
        .replace(hideStr, 'style="display: none;"')
        .replace(buttonName, '');
    }
  }

  /***********************************
  * create - creates an alert to add to a pre located element (div?)
  *   it is passed a configuration object with the following parameters

  */
  create(config:AlertConfig)
  {
    // Must have setup basic support
    if( config && config.id && config.alertTypeConfig && config.message && config.title)
    {
      // must be able to find div
      let $alertDiv=$('#' + config.id);
      if( $alertDiv )
      {
        // Load config with defaults
        _.defaults(config, {
          // These are the defaults we setup
          alertTypeButton:config.alertTypeConfig,
          successButton:'Ok',
          cancelButton:'Cancel' });
        // Now make htmlStr be what we want by doing appropriate String
        // replacements
        let htmlStr = this.alertTemplate
          .replace("{{alertTypeConfig}}",config.alertTypeConfig)
          .replace('{{alertTypeButton}}', config.alertTypeButton)
          .replace('{{title}}',config.title)
          .replace('{{message}}',config.message);

        // Only configure button panel if they have callbacks
        htmlStr = this.setButton(htmlStr, '{{hidden-success}}', '{{successButton}}', config.successButton);
        htmlStr = this.setButton(htmlStr, '{{hidden-cancel}}', '{{cancelButton}}', config.cancelButton);

        // Set the div
        this.destroy($alertDiv);
        console.log(htmlStr);
        $alertDiv.append(htmlStr);

        $alertDiv.find( ".myalert-OkButton" ).on( "click",
          () => this.destroy($alertDiv,config,true)
        );
        $alertDiv.find( ".myalert-closeButton" ).on( "click",
          () => this.destroy($alertDiv,config,false)
        );
        if( config.timeout )
          setTimeout(() => this.destroy($alertDiv,config,false), config.timeout );
      }
    }
  }
}
