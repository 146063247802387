import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { RRServiceVideoData } from "../../services/rr.service.videodata";
import { RRServiceVideoPopup } from "../../services/rr.service.videopopup";



@Component({
  selector: 'app-salestraining',
  templateUrl: './salestraining.component.html',
  styleUrls: ['./salestraining.component.css']
})
export class SalestrainingComponent implements OnInit
{

  RR_MEDIA_TYPE_KEY;
  isAudio:boolean;
  icon:string;
  videoListSales:Array<any>  = [
    { name: 'introp1', desc:'Welcome' },
    { name: 'introp2', desc:'Introduction' },
    { name: 'introp3', desc:'Introduction Cont.' },
    { name: 'pg'   , desc:'Power Greeting' },
    { name: 'pa'   , desc:'Pre-Appraisal' },
    { name: 'sel'  , desc:'Selection' },
    { name: 'td'   , desc:'Test Drive' },
    { name: 'ho'   , desc:'Hood Ornament Presentation' },
    { name: 'uce'  , desc:'Used Car Evaluation' },
    { name: 'ce'   , desc:'Comparison Example' },
    { name: 'cs'   , desc:'Customer Statement' },
    { name: 'close', desc:'Closing' },
    { name: 'del'  , desc:'Delivery' },
  ];
  videoListSales2:Array<any>  = [
    { name: 'p2_intro', desc:'Introduction', enabled: true },
    { name: 'p2_power_greeting', desc:'Power Greeting' , enabled: true},
    { name: 'p2_pre_appraisal', desc:'Pre-Appraisal' , enabled: true},
    { name: 'p2_selection_alternate_selection', desc:'Selection/Alternate Selection', enabled: true },
    { name: 'p2_emotional_test_drive', desc:'Emotional Test Drive', enabled: true },
    { name: 'p2_hood_ornament', desc:'Hood Ornament Presentation' , enabled: true},
    { name: 'p2_service_introduction', desc:'Service Introduction', enabled: true },
    { name: 'p2_dealership_tour', desc:'Dealership Tour', enabled: true },
    { name: 'p2_used_car_evaluation', desc:'Used Car Evaluation Sheet', enabled: true },
    { name: 'p2_comparison_example', desc:'Comparison Example', enabled: true },
    { name: 'p2_write_up', desc:'Write Up', enabled: true },
    { name: 'p2_hfo_new', desc:'House First Offer New', enabled: true },
    { name: 'p2_hfo_used', desc:'House First Offer Used', enabled: true },
    { name: 'p2_dar', desc:'Delivery And Referral', enabled: true },
    { name: 'p2_question_mark', desc:'Question Mark', enabled: true },
    { name: 'p2_white_horse', desc: 'White Horse Summary', enabled: true },
  ];

  private isCollapsed:boolean;
  private isCollapsed2:boolean;

  get iconDir(): string {
    return this.isCollapsed ? 'down' : 'up';
  }

  get iconDir2(): string {
    return this.isCollapsed2 ? 'down' : 'up';
  }
  private setCat(vids:Array<any>, cat:string)
  {
    vids.forEach((vid) => {
      vid.cat = cat;
      vid.url = this._rrServiceVideoData.poster(vid.cat, vid.name);
    });

  }

  constructor(
    private _rrServiceVideoData:RRServiceVideoData,
    private _rrServiceVideoPopup: RRServiceVideoPopup )
  {
    this.RR_MEDIA_TYPE_KEY = 'rr.salestraining.mediatype.key';
    this.setCat(this.videoListSales, 'sales');
    this.setCat(this.videoListSales2, 'sales2');
  }

  ngOnInit() {
    this.setMediaType(localStorage.getItem(this.RR_MEDIA_TYPE_KEY) ? true : false);
    console.log('SalesTraining.ngOnInit()', this.isAudio);
  }

  ngOnDestroy() {
    console.log('SalesTraining.ngOnDestroy()')
  }

  private updateClass(id:string, className:string, isAdd:boolean)
  {
    var elem = document.getElementById(id);
    if( elem )
    {
      try {
        if( isAdd ) elem.classList.add(className);
        else elem.classList.remove(className);
      } catch( err ) {
        console.warn("SalesTraining.updateClass error:", elem, err);
      }
    }
  }

  private setMediaType(isAudio:boolean)
  {
    if( (typeof this.isAudio === 'boolean') && isAudio == this.isAudio )
      return;
    if( isAudio )
    {
      this.icon='fa fa-volume-up';
      this.isAudio = true;
      localStorage.setItem(this.RR_MEDIA_TYPE_KEY,'true');
    } else {
      this.icon='fa fa-video-camera';
      this.isAudio = false;
      localStorage.removeItem(this.RR_MEDIA_TYPE_KEY);
    }
    this.updateClass('mediaTypeVideo', 'active', !isAudio);
    this.updateClass('mediaTypeAudio', 'active', isAudio);
    console.log("SalesTraining.setMediaType", isAudio);
  }

  private setVideo(vid:any)
  {
    this._rrServiceVideoPopup.showVideo(vid.cat, vid.name, vid.desc, this.isAudio);
    console.log('SalesTraining.selectVideo()', vid);
  }
}
