import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';

import {RRConfig} from './rr.service.config';

@Injectable({ providedIn: 'root'})
export class RRServiceHttp {

  constructor(private http: HttpClient, private config:RRConfig) {}

  request(method: string, url: string, body:any,
    headers?: HttpHeaders, params?: HttpParams ): Observable<RRData.StdRet>
  {
    return this.http.request<RRData.StdRet>(method, url, {
      body: body, headers: headers, params: params,
      observe: 'body', responseType: 'json'
    });
  }
}
