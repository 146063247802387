import { Injectable } from '@angular/core';
import {Subscription, Subject, BehaviorSubject, Observable} from 'rxjs/Rx';
import {RRServiceUserList} from "./rr.service.userlist";
import {RRServiceServer} from "./rr.service.server";
import * as _ from 'lodash';

declare type SalesStatsSignaler = (value: RRData.SalesStatsData) => void;

@Injectable({ providedIn: 'root'})
export class RRServiceOtdb {

  private subscription: Subscription;
  private salesStatsSubject:BehaviorSubject<RRData.SalesStatsData>;
  private lastSearch:any;

  constructor(
    private _rrServiceServer:RRServiceServer,
    private _rrServiceUserList:RRServiceUserList)
  {
    this.salesStatsSubject = new BehaviorSubject<RRData.SalesStatsData>(null);
    // Technically since this is a singleton instance
    // we will never need to hold onto the userSubscription
    // in order to unsubscripe - but I leave it here in the case
    // we go to a child injector model
    this.subscription =
      this._rrServiceUserList.subscribeSelectedUser(
        user => {
          if( user )
          {
            var search = {
              dealershipId:this._rrServiceUserList.getCurDealershipId(),
              userId:(user ? user.userId : 0)
            };
            if(! _.isEqual(search, this.lastSearch) )
            {
              this.lastSearch = search;
              console.log('RRServiceOtdb.subscribe().user = ',user, search);
              this._rrServiceServer.get('otdbStats',{search: search } )
                .map( ret => <RRData.SalesStatsData>ret.result )
                .subscribe(
                  ret => {
                    this.salesStatsSubject.next(ret);
                    console.log('RRServiceOtdb.subscribe().next: ',ret)
                  },
                  err => console.log('RRServiceOtdb.subscribe().err: ',err) );
            }
          }
        } );
  }

  subscribeOtdbResponse( signal: SalesStatsSignaler ): Subscription
  {
    return this.salesStatsSubject.subscribe( signal );
  }

  private apiCall(id?:number) { return 'otdb' + (id ? ('/' + id) : ''); }

  updateOtdb(otdb:RRData.Otdb): Observable<RRData.Otdb>
  {
    return this._rrServiceServer.post(
        this.apiCall(otdb.otdbId), { body: JSON.stringify(otdb) } )
      .map( ret => <RRData.Otdb>ret.result ); // Map result to user
  }

  getOtdb(otdb:RRData.Otdb): Observable<RRData.Otdb>
  {
    return this._rrServiceServer.get(
        this.apiCall(),
        { search: { dealershipId:otdb.dealershipId, userId:otdb.userId, entryDate:otdb.entryDate, } } )
      .map( ret => <RRData.Otdb>ret.result ); // Map result to user
  }

  deleteOtdb(otdb:RRData.Otdb): Observable<RRData.Otdb>
  {
    return this._rrServiceServer.delete(this.apiCall(otdb.otdbId))
      .map( ret => <RRData.Otdb>ret.result ); // Map result to user
  }

}
