/**
 * RapidRideService connects to the RapidRide API
 */
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observer } from 'rxjs/Observer';
import { Observable, Subscription, Subject, BehaviorSubject } from 'rxjs/Rx';

import { RRServiceHttp } from "./rr.service.http";
import { HttpCodes } from "./httpcodes";

import * as _ from 'lodash';


/**
* RRServerError is thrown on problems from the server
*/
export enum RRServerState {
  Failed,
  Connected,
}

@Injectable({ providedIn: 'root'})
export class RRServiceServer {
  private RR_AUTH_KEY: string;
  // Key we use to store authentication parameters

  private subjectUser: BehaviorSubject<RRData.User>;
  private subjectState: BehaviorSubject<RRServerState>;

  private auth:string;

  // Authentication parameter
  private getAuth(): string { return this.auth; }


  constructor(private http:RRServiceHttp, private codes:HttpCodes) {
    this.RR_AUTH_KEY = 'rr.service.server.auth.key';
    this.auth = localStorage.getItem(this.RR_AUTH_KEY);

    // subjectUser is managed at the login handler level
    this.subjectUser = new BehaviorSubject<RRData.User>(null);

    // subjectState is managed at the request handler level
    this.subjectState = new BehaviorSubject<RRServerState>(RRServerState.Failed);

    // Try a login from stored parameter
    if( this.auth )
    {
      this.loginWithAuthParam( this.auth )
        .subscribe(
          ret => console.log('RRServiceServer.loginWithAuthParam().subscribe().next: ',ret),
          err => console.log('RRServiceServer.loginWithAuthParam().subscribe().err: ',err) );
    }
  }


  private addHeader(headers: HttpHeaders, key:string, value:string)
  {
    if( !headers ) headers = new HttpHeaders();
    return headers.append(key, value);
  }

  addParam(params: HttpParams, key:string, value:string ): HttpParams
  {
    if( ! params ) params = new HttpParams();
    return params.append(key,value);
  }

  serverUrl( apiCall:string ): string
  {
    return 'rapid-ride/api/' + apiCall;
  }


  // make a request to the server. Req is a java object
  // with various parameters. If there is no request
  // authorization header and we have one saved in local
  // storage then it's appended. Return is an observable
  // that will stream the response when it's ready.
  private request(method:string, apiCall:string, args:any): Observable<RRData.StdRet>
  {
    let body = undefined;
    let auth = undefined;
    let headers: HttpHeaders = undefined;
    let params: HttpParams = undefined;
    if( args )
    {
      let search=undefined;
      ['auth', 'body', 'search'].forEach((key) => {
        if( args[key] )
        {
          switch( key )
          {
            case 'auth': auth = args[key]; break;
            case 'body': body = args[key]; break;
            case 'search': search = args[key]; break;
          }
          delete args[key];
        }
      });
      if( search )
        _.forEach(search,(value,key) => params = this.addParam(params, key, value ));
      if( args )
        _.forEach(args,(value,key) => params = this.addParam(params, key, value ));
    }

    headers = this.addHeader( headers, 'Content-Type', 'application/json' );
    if( auth )
      headers = this.addHeader( headers, 'Authorization', 'Basic ' + auth );

    return this.http.request(method, this.serverUrl( apiCall ), body, headers, params )
      .catch( error => {
        // This is where any network error, and non 2xx status code
        // come to die. In our rest api the only thing we really need
        // is the status code + message so we convert to
        // <string> "(statsCode) Msg"
        console.error('RRServiceServer.http.request().catch().error',error);
        var errMsg = this.codes.statusCodeToMessage(error.status);
        if( error.status == 0 || error.status > 500 )
            this.subjectState.next(RRServerState.Failed);
	    try {
	    errMsg = errMsg + '\n' + '<strong>' + error.error.errorMsg + '</strong><br/>';
	var x = errMsg.split("\n");
	errMsg = x.join("<br/>");
        } catch (err) {
          console.log('this.http.request', err, errMsg);
        }
        console.log('this.http.request', error);
        return Observable.throw( errMsg );
      })
      .do( resp => this.subjectState.next(RRServerState.Connected) );
  }

  get(apiCall:string, req?:any): Observable<RRData.StdRet>
  {
    return this.request('GET', apiCall, req);
  }

  post(apiCall:string, req?:any): Observable<RRData.StdRet>
  {
    return this.request('POST', apiCall, req);
  }

  delete(apiCall:string, req?:any): Observable<RRData.StdRet>
  {
    return this.request('DELETE', apiCall, req);
  }

  private nextUser(user:RRData.User)
  {
    if( !_.isEqual(this.subjectUser.getValue(), user) ) this.subjectUser.next(user);

  }

  // Try and login via an authentication parameter. If it's valid
  // then save it
  private loginWithAuthParam(param: string): Observable<RRData.User> {
    // do a login from auth param
    return this.get( 'auth/login', {auth:param} )
      .map( ret => <RRData.User>ret.result )
      .do( user => this.nextUser(user) );
  }

  // logout the current user and clear localstorage
  logout(): void {
    localStorage.removeItem(this.RR_AUTH_KEY);
    this.auth=null;
    this.get('auth/logoff')
      .finally(() => this.nextUser(null))
      .subscribe(
        ret => console.log('RRServiceServer.logout().subscribe().next: ',ret),
        err => console.log('RRServiceServer.logout().subscribe().err: ',err) );
  }

  // attempt to login a user. Starts by clearing any existing info
  login(value:any): Observable<RRData.User>
  {
    this.logout();
    var auth = window.btoa(value.dealer + "\t" + value.username + ':' + value.password);
    return this.loginWithAuthParam(auth)
      .do(
        resp => {
          this.auth=auth;
          if( value.remember )
            localStorage.setItem(this.RR_AUTH_KEY,this.auth);
        }
      );
  }

  subscribeUserStream( onNext: (value: RRData.User) => void ): Subscription
  {
    return this.subjectUser.subscribe(onNext);
  }

  subscribeStateStream( onNext: (value: RRServerState) => void): Subscription
  {
    return this.subjectState.subscribe(onNext);
  }

  unSubscribeStream( subscription: Subscription ){ subscription.unsubscribe(); }

}
