import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';

import { RRServiceSeminarTransactionList } from "../services/rr.service.seminartransactionlist";

declare let paypal: any;

@Component({
  selector: 'app-seminars',
  templateUrl: './seminars.component.html',
  styleUrls: ['./seminars.component.css']
})

export class SeminarsComponent implements OnInit {

  days;
  hours;
  minutes;
  seconds;
  addScript: boolean = false;
  paypalLoad: boolean = true;
  hasPaid: boolean = false;
  hasRegistered: boolean = false;
  submitted:boolean;
  finalAmount: number = 1;
  waiting:boolean;
  transactionId: number = 0;
  name: string;
  email: string;
  dealership: string;
  position: string;
  transactionAmount: number;
  transactionDate: number;
  confirmationNumber:string;
  version: number;

  @Input('seminar') user:RRData.SeminarTransaction;

  paypalConfig = {
    env: 'production',
    client: {
      sandbox: 'AbC98IzLfJ28wbZuC3P69zMLbB6D6KdFE_0o58r2fwhSrVb3RPGc1WADatHSmmmL80WDdMjtKNMgENVc',
      production: 'AapS2OprtxShH80gcqAcd_dluYQQx4tlMf2a_fC4WshAoDW7LuQ8hC4rgIW8e6uKwVLuX6UJ54vvvmRK'
    },
    commit: true,
    payment: (data, actions) => {
      return actions.payment.create({
        payment: {
          transactions: [
            { amount: { total: this.finalAmount * 750, currency: 'USD' } }
          ]
        }
      });
    },
    onAuthorize: (data, actions) => {
      return actions.payment.execute().then((payment) => {
        this.hasPaid = true;
        this.transactionId = this._rrServiceSeminarTransactionList.getTransactionId()
        this.confirmationNumber = payment.id;
        let transaction2 =
                            {transactionId: this.transactionId,
                            name: this.name,
                            email: this.email,
                            dealership: this.dealership,
                            position: this.position,
                            confirmationNumber: this.confirmationNumber,
                            transactionAmount: this.finalAmount * 750,
                            transactionDate: 0,
                            version: 0};
        console.log("payment obj: ", payment);
        this._rrServiceSeminarTransactionList.updateSeminarTransaction(transaction2);
      })
    }
  };

  constructor(private _rrServiceSeminarTransactionList:RRServiceSeminarTransactionList)
  {
  }


  setTime(seconds: number, minutes, hours, days){
    this.seconds = seconds
  }

  ngOnInit() {
    var countDownDate = new Date("Oct 7, 2019 08:00:0").getTime();
    var x = setInterval((function() {

      // Get todays date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = (Math.floor((distance % (1000 * 60)) / 1000));
    }).bind(this), 1000);
  }

  onSubmit() {
    if (!this.addScript) {
      this.addPaypalScript().then(() => {
        paypal.Button.render(this.paypalConfig, '#paypal-checkout-btn');
        this.paypalLoad = false;
      })
    }
    this.submitted = true;
    this.hasRegistered = true;
    var d = new Date();
    let transaction =
                        {transactionId: this.transactionId,
                        name: this.name,
                        email: this.email,
                        dealership: this.dealership,
                        position: this.position,
                        confirmationNumber: "",
                        transactionAmount: this.finalAmount * 750,
                        transactionDate: d.getTime(),
                        version: 0};
    this._rrServiceSeminarTransactionList.updateSeminarTransaction(transaction);
  }

  goBack(){
    this.transactionId = this._rrServiceSeminarTransactionList.getTransactionId();
    this.hasRegistered = false;
    this.addScript = false;
  }

  addPaypalScript() {
    this.addScript = true;
    return new Promise((resolve, reject) => {
      let scripttagElement = document.createElement('script');
      scripttagElement.src = 'https://www.paypalobjects.com/api/checkout.js';
      scripttagElement.onload = resolve;
      document.body.appendChild(scripttagElement);
    })
  }
}
