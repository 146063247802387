import {Component, OnInit, OnDestroy} from '@angular/core';

import {Subscription} from 'rxjs/Subscription';

import {RRServiceDealerList} from "../../services/rr.service.dealerlist";
import {RRServiceUserState} from "../../services/rr.service.userstate";

import * as $ from 'jquery';

@Component({
  selector: 'app-dealerselect',
  templateUrl: './dealerselect.component.html',
  styleUrls: ['./dealerselect.component.css']
})
export class DealerselectComponent implements OnInit, OnDestroy {

  private dealers: Array<RRData.Dealer>;
  private dealershipId:number;
  private dealerListSubscription: Subscription;
  private selectedDealerSubscription: Subscription;

  constructor(
    private _rrServiceUserState:RRServiceUserState,
    private _rrServiceDealerList:RRServiceDealerList
  )
    {
    this.dealers = _rrServiceDealerList.getDealers();
    this.dealershipId = 0;
    this.dealerListSubscription =
      _rrServiceDealerList.subscribeDealerList(
        dealers => this.dealers = dealers );
    this.selectedDealerSubscription =
      _rrServiceDealerList.subscribeSelectedDealer(
        dealer => this.dealershipId = dealer ? dealer.dealershipId : 0 );
  }

  ngOnInit() {
    console.log('DealerSelect.ngOnInit()');
  }

  ngOnDestroy() {
    console.log('DealerSelect.ngOnDestroy()');
    this.dealerListSubscription.unsubscribe();
    this.selectedDealerSubscription.unsubscribe();
  }

  selectDealer(value:string)
  {
    var dealershipId:number;
    try {
      dealershipId = parseInt(value,10);
    } catch( err ) {
      console.error('DealerSelect.selectDealer() value:', value, err);
      dealershipId = 0;
    }
    console.log('DealerSelect.selectDealer() dealershipId:', dealershipId);
    this._rrServiceDealerList.setCurDealer(dealershipId);
  }

}
