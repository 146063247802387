import { Injectable } from '@angular/core';
import {Observable, Subscription, Subject, BehaviorSubject} from 'rxjs/Rx';
import {RRServiceServer} from "./rr.service.server";

declare var $:any;
declare type Signaler = (value: boolean) => void;

@Injectable({ providedIn: 'root'})
export class RRServiceUserState {

  user: RRData.User;
  userSubscription:Subscription;
  subject:BehaviorSubject<boolean>;

  constructor(public _rrServiceServer:RRServiceServer) {
    this.subject = new BehaviorSubject<boolean>(false);
    // Technically since this is a singleton instance
    // we will never need to hold onto the userSubscription
    // in order to unsubscripe - but I leave it here in the case
    // we go to a child injector model
    this.userSubscription =
      this._rrServiceServer.subscribeUserStream(
            user => {
              this.user = user;
              this.subject.next(this.isLoggedIn());

              console.log("RRServiceUserState.subscribeUserStream().next: ", this.getUserName());
            });
  }

  updateUser(u:RRData.User)
  {
    if( (u.userId == this.user.userId) ) this.user = u;
  }
  getUser() { return this.user }
  isLoggedIn() { return this.user ? true : false }

  private getValue( field:any, defaultValue?:any)
  {
    return (this.user && this.user[field]) ? this.user[field] : defaultValue;
  }

  getUserName() { return this.getValue( 'name', "" ); }
  getUserId() { return this.getValue( 'userId', 0 ); }
  getDealershipId() { return this.getValue( 'dealershipId', 0 ); }
  getAdminLevel() { return this.getValue('adminLevel',RRData.ADMIN_LEVEL.NORMAL); }
  isSystemAdmin() { return this.getAdminLevel()==RRData.ADMIN_LEVEL.SYSTEM_ADMIN; }
  isSiteAdmin() { return this.getAdminLevel()>=RRData.ADMIN_LEVEL.SITE_ADMIN; }
  isTeamLeader() { return this.getAdminLevel()>=RRData.ADMIN_LEVEL.TEAM_LEADER; }
  userImage() { return this.getValue( 'image', "assets/img/picture.png" ); }


  subscribe( signal: Signaler ): Subscription
  {
    // when redy simply unsubscribe as follows
    // var subscription = subscripe(handler);
    // subscription.unsubscribe();
    return this.subject.subscribe( signal );
  }


  login(value:any): Observable<RRData.User>
  {
    return this._rrServiceServer.login(value);
  }

  logout(): void { this._rrServiceServer.logout(); }

  tooltipHide() { $('[data-toggle="tooltip"]').tooltip('hide'); }

}
