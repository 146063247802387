import { Injectable } from '@angular/core';
import {Subscription, Subject, BehaviorSubject, Observable} from 'rxjs/Rx';
import {RRServiceServer} from "./rr.service.server";
import {RRServiceDealerList} from "./rr.service.dealerlist";
import {RRServiceUserState} from "./rr.service.userstate";
import * as _ from 'lodash';

declare type Signaler = (value: Array<RRData.User>) => void;
declare type UserSignaler = (value: RRData.User) => void;

@Injectable({ providedIn: 'root'})
export class RRServiceUserList {

  private users: Array<RRData.User>;
  private curUser: RRData.User;
  private curDealersipId:number;
  private subscription: Subscription;
  private userListSubject:BehaviorSubject<Array<RRData.User>>;
  private selectedUserSubject:BehaviorSubject<RRData.User>;

  constructor( private _rrServiceDealerList:RRServiceDealerList,
    private _rrServiceServer:RRServiceServer,
    private _rrServiceUserState:RRServiceUserState ) {
    this.users = new Array<RRData.User>();
    this.curDealersipId=0;
    this.userListSubject = new BehaviorSubject<Array<RRData.User>>(this.users);
    this.selectedUserSubject = new BehaviorSubject<RRData.User>(null);


    // Technically since this is a singleton instance
    // we will never need to hold onto the subscription
    // in order to unsubscripe - but I leave it here in the case
    // we go to a child injector model
    this.subscription =
      _rrServiceDealerList.subscribeSelectedDealer( dealer => {
        if( !dealer ) this.clearUsers();
        else this.fetchUsers(dealer.dealershipId);
      });

  }

  private publish() {
    if( this.users.length )
        this.users = _.sortBy(this.users, ['name','userId'] );
    this.userListSubject.next(_.cloneDeep(this.users));
  }

  private publishUser() {
    this.selectedUserSubject.next(this.curUser);
  }

  subscribeUserList( signal: Signaler ): Subscription
  {
    return this.userListSubject.subscribe( signal );
  }

  subscribeSelectedUser( signal: UserSignaler ): Subscription
  {
    return this.selectedUserSubject.subscribe( signal );
  }

  private clearUsers() {
    this.curUser=null;
    this.curDealersipId=0;
    this.users.length=0;
    this.publish();
    this.publishUser();
  }
  getUsers() { return this.users; }
  getCurUser() { return this.curUser; }

  setCurUser(userId:number) {
    this.curUser = _.find(this.users,{userId:userId});
    this.publishUser();
  }

  isMultiUser() { return this.users.length > 1 ? true : false; }

  getCurDealershipId():number { return this.curDealersipId; }

  private apiCall(id?:number) { return 'user' + (id ? ('/' + id) : ''); }
  fetchUsers(dealershipId:number)
  {
    this._rrServiceServer.get(this.apiCall(), {search:{dealershipId: dealershipId}})
      .map( ret => <Array<RRData.User>>ret.result )
      .subscribe(
          ret => {
            this.curDealersipId=dealershipId;
            this.users = ret;
            this.setCurUser(this._rrServiceUserState.getUserId());
            this.publish();
          },
          err => {
            this.clearUsers();
            console.error('RRServiceUserList.fetchUsers().catch(): ', err);
          }
      );
  }

  updateUser(user:RRData.User): Observable<RRData.User>
  {
    return this._rrServiceServer.post(
        this.apiCall(user.userId), { body: JSON.stringify(user) } )
      .map( ret => <RRData.User>ret.result ) // Map result to user
      .do( user =>
        { // update my local cache
          if( user )
          {
            var idx = _.findIndex(this.users, d => d.userId == user.userId );
            if( idx >= 0 ) this.users[idx] = user;
            else this.users.push(user);
            this.publish();
            this.setCurUser(user.userId);
            this._rrServiceUserState.updateUser(user);
          }
        }
      );
  }

  deleteUser(user:RRData.User): Observable<RRData.User>
  {
    return this._rrServiceServer.delete(
        this.apiCall(user.userId))
      .map( ret => <RRData.User>ret.result ) // Map result to user
      .do( user => {
        if( user )
        {
          _.remove(this.users, u => (u.userId == user.userId) );
          this.publish();
          if( this.curUser && this.curUser.userId == user.userId )
            this.setCurUser(0);
        }
      });
  }
}
