import { Component, OnInit, OnDestroy } from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';

import {RRServiceVideo} from '../services/rr.service.video';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit, OnDestroy {

  videoDiv:any;

  constructor(private activatedRoute: ActivatedRoute,
    private _RRServiceVideo: RRServiceVideo ) {}

  ngOnInit() {
    // subscribe to router event
    this.activatedRoute.params.subscribe((params: Params) => {
      let autoStart = params['autoStart'] ?  true : false;
      this.videoDiv = document.getElementById("video-tour");
      this._RRServiceVideo.onInitDiv( this.videoDiv, 'sales', 'rr_tour', false, autoStart );
      console.log("Welcome.ngOnInit() ", this.videoDiv, autoStart );
      });
  }

  ngOnDestroy() {
    // this._RRServiceVideo.onDestroyDiv( this.videoDiv, 'sales' );
    console.log('Welcome.ngOnDestroy()');
  }

}
