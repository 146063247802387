import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { RRServiceVideoData } from "../../services/rr.service.videodata";
import { RRServiceGalleryVideo } from "../../services/rr.service.galleryvideo";
import { RRServiceHttp } from "../../services/rr.service.http";
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

export class YearInfo {
  year:number
  collapsed:boolean
  accelList:Array<any>;
}


@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})

export class GalleryComponent implements OnInit
{

  RR_MEDIA_TYPE_KEY;
  isAudio:boolean;
  icon:string;
  yearList:Array<YearInfo>;
  quoteToggleList:Array<boolean>;

  private ready() : boolean { return this.yearList.length > 0 }
  getIconDir(yi:YearInfo): string {
    return this.isCollapsed(yi) ? 'down' : 'up';
  }

  collapsed(yi:YearInfo){

  }
  expanded(yi:YearInfo){

  }
  isCollapsed(yi:YearInfo):boolean {
    return yi.collapsed
  }

  isQuoteCollapsed(index:number):boolean {
    return this.quoteToggleList[index]
  }

  singleYearList(yi:YearInfo) : Array<any> {
    return null;
  }

  setCollapsed(yi:YearInfo){
    yi.collapsed = !yi.collapsed
  }

  toggleQuote(index:number){
    this.quoteToggleList[index] = !this.quoteToggleList[index]
  }

  constructor(
    private _rrServiceVideoData:RRServiceVideoData,
    private _rrServiceGalleryVideo: RRServiceGalleryVideo,
    )
  {
    this.yearList = [];
    console.log("Gallery component");
  }

  ngOnInit() {
    this._rrServiceGalleryVideo.accelList.subscribe(res => {
        this.yearList = [];
        this.quoteToggleList = []
        let n = Math.ceil(res.length/52);
        for(var i=0; i<n; i++) {
          let idx = 52*i
          let yi = new YearInfo()
          yi.year = i+2018
          yi.collapsed=true
          yi.accelList=res.slice( idx, idx+52 );
          this.yearList.push(yi);
        }
        for(var i = 0; i < res.length; i++){
          this.quoteToggleList.push(true)
        }
        console.log("Accel Lists: ", this.ready)
    })

  }

  ngOnDestroy() {
    console.log('Gallery.ngOnDestroy()')
  }

  private setVideo(vid:any)
  {
    this._rrServiceGalleryVideo.showVideo(this._rrServiceVideoData.AccelGalleryName(vid.Idx));
  }
}

/*



    <div id="homeAccordion" *ngFor = "let vid of accelList; let i = index">
      <div class="card" *ngIf = "i%52==0" >

      </div>
    </div>
*/
