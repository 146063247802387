import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import {RRServiceServer} from "../../services/rr.service.server";

import * as $ from 'jquery';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit, OnDestroy {

  @Input('page') page:RRData.Page; // stored value
  @Input('pageNo') pageNo:number; // stored value
  @Input('numPages') numPages:number; // stored value
  href:string;


  constructor(private _rrServiceServer:RRServiceServer) {
    console.log("Thumbnail");
  }

  gethref()
	{
    return '/protected/books/' + this.page.category.toLowerCase()
      + '/pdf/' + encodeURIComponent(this.page.title) + '.pdf';
	}

  ngOnInit() {
    this.href = this.gethref();

    console.log("Thumbnail.ngOnInit()", this.href);
  }

  ngOnDestroy() {
    console.log("Thumbnail.ngOnDestroy()");
  }

}
