import { Component, OnInit, OnDestroy, AfterViewChecked } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subscriber } from 'rxjs/Subscriber';

/*
 * Services
 */
import {RRServiceUserState} from "../services/rr.service.userstate";
import {RRServiceUserList} from "../services/rr.service.userlist";
import {HomeComponent} from "../tools/home/home.component";
import {LoginComponent} from "../tools/login/login.component";
import {DealerselectComponent} from "../helpers/dealerselect/dealerselect.component";
import {CropavatarComponent} from "../helpers/cropavatar/cropavatar.component";


import {SalestrainingComponent} from "../tools/salestraining/salestraining.component";
import {UsereditComponent} from "../tools/useredit/useredit.component";
import {DealereditComponent} from "../tools/dealeredit/dealeredit.component";
import {OtdbComponent} from "../tools/otdb/otdb.component";
import {ReportsComponent} from "../tools/reports/reports.component";
import {PagesComponent} from "../tools/pages/pages.component";
import {GalleryComponent} from "../tools/gallery/gallery.component"

import * as _ from 'lodash';

class RouteOption {
  name: string;
  display: string;
  icon: string;
  isDisplay: boolean;

  constructor( name: string, display: string, icon: string, isDisplay : boolean) {
    this.name = name;
    this.display = display;
    this.icon = icon;
    this.isDisplay = isDisplay;
  }
}


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewChecked {

  routerOptions:Array<RouteOption> = [
    new RouteOption('Home',           'Home',             'fa fa-home black-ico',             true),
    new RouteOption('Otdb',           'OTDB',             'fa fa-check-square-o',     true),
    new RouteOption('SalesTraining',  'Sales Training',   'fa fa-file-video-o',   true),
    new RouteOption('Gallery',        'Gallery',          'fa fa-file-video-o',   true),
    new RouteOption('Reports',        'Reports',          'fa fa-list-alt',         true),
    new RouteOption('Documentation',  'Documentation',    'fa fa-book',             true),
    new RouteOption('Users',          'User Setup',       'fa fa-pencil-square-o',  true),
    new RouteOption('Dealers',        'Dealer Setup',     'fa fa-pencil-square',    true),
  ];

  adminStatusArray:Array<any> = [
    { name: 'Member', icon:'fa fa-user' },
    { name: 'Leader', icon:'fa fa-user-plus' },
    { name: 'Admin',  icon:'fa fa-unlock-alt' },
    { name: 'Admin',  icon:'fa fa-unlock' },
  ];

  adminStatus:any;
  userSubscription: Subscription;

  constructor( private _rrServiceUserState: RRServiceUserState,
    private _rrServiceUserList: RRServiceUserList) {
    }

  avatar(image:string)
  {
    var user:RRData.User = this._rrServiceUserState.getUser();
    if( user )
    {
      user.image = image;
      this._rrServiceUserList.updateUser(user)
        .subscribe(
          (ret) => {console.log("avatar.ok", ret)},
          (err) => {console.log("avatar.err", err)});
    } else {
      console.warn("Dashboard.avatar - null user");
    }
  }

  private setStyleParam(elem:any, name:string, value:string)
  {
    try {
      if( elem.style[name] != value )
      {
        elem.style[name] = value;
        return true;
      }
    } catch (err) {
    }
    return false;
  }

  private setSidebarRightHeight()
  {
    var elems = document.getElementsByClassName("dealer-edit-jum");
    if( elems )
    {
      try {
        var left = document.getElementById('sidebar-left')
        if( left )
        {
          var dirty = false;
          var minHeight = left.clientHeight + 'px';
          for( var idx=0; idx<elems.length; idx++ )
            if( this.setStyleParam(elems[idx], 'minHeight', minHeight) ) dirty=true;
          if( dirty )
            console.log("Dashboard.ngAfterViewInit(): ", left, elems, minHeight );
        }
      } catch( err ) {
        console.log("Dashboard.ngAfterViewInit() err: ", elems, err );
      }
    }
  }

  ngAfterViewChecked() {
    this.setSidebarRightHeight();
  }


  ngOnInit() {
    console.log('Dashboard.ngOnInit().subscribe');
    this.userSubscription = this._rrServiceUserState.subscribe(
      resp => {
        var adminLevel = this._rrServiceUserState.getAdminLevel();
        if( adminLevel < 0 || adminLevel >= this.adminStatusArray.length )
          adminLevel=0;
        var opt = _.find(this.routerOptions, { 'name': 'Dealers' } );
        if( opt ) {
          opt.isDisplay = adminLevel>=RRData.ADMIN_LEVEL.SITE_ADMIN;
        }
        this.adminStatus = this.adminStatusArray[adminLevel];
        console.log('Dashboard.ngOnInit().subscribe().next: ', resp, "adminStatus:", this.adminStatus);
      }
    );
  }



  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    console.log('Dashboard.ngOnDestroy().unSubscribe')
  }

}
